// Chakra imports
import {

  Box,
  Flex,

  Icon,

  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import { MdBugReport, MdCancel, MdLocalGroceryStore, MdOutlineVerifiedUser, MdVerified, MdVerifiedUser } from "react-icons/md";

import { FaCoins } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import { useUsersData } from "variables/useUsersData";
// Importa las variables desde statistics.js
import useExchangeProducts from "variables/useExchangeProducts";
import useBugsReport from "variables/useBugsRepots";
import usePoints from "variables/usePoints";
import { useKyc } from "variables/useKyc";
import MiniCalendar from "components/calendar/MiniCalendar";
import Dailytraffic from "./components/DailyTraffic";



export default function UserReports() {


  
  const {totalBugsReports} =useBugsReport();
  const {totalExhangeData} = useExchangeProducts();
 const { totalUsersCount, usersTotalPoints} = useUsersData();
 const {pointsCompany}= usePoints()
 const [pointsTotal,setPoinstotal] = useState("");
 const {summary} = useKyc();


 useEffect(() =>{
  setPoinstotal(pointsCompany + usersTotalPoints)
 },[pointsCompany, usersTotalPoints])
 
// Chakra Color Mode
  const brandColor = useColorModeValue("color.3", "color.7");
  const boxBg = useColorModeValue("color.7", "color.2");
  //const textColor = useColorModeValue("gray.700", "white");

  return (
    <Flex maxWidth="100%" direction="column" >
      <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={FaCoins} color={brandColor} />}
            />
          }
          name="Puntos totales"
          value={"$ " + pointsTotal}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={FaCoins} color={brandColor} />}
            />
          }
          name="Puntos de los Usuarios"
          value={"$ " + usersTotalPoints}
        />
        <MiniStatistics

          name="Puntos de la Compañia"
          value={"$ " + pointsCompany}
          startContent={
          <IconBox 
          icon={
            <Icon w="32px" h="32px" as={FaCoins} color={brandColor} />
          } 
          />
          }

        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="28px"
                  h="28px"
                  as={MdLocalGroceryStore}
                  color={brandColor} 
                />
              }
            />
          }
          name="Total Compras"
          value={totalExhangeData}
        />

        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="28px" h="28px" as={ImUsers}color={brandColor}  />}
            />
          }
          name="Usuarios"
          value={totalUsersCount}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBugReport} color={brandColor} />
              }
            />
          }
          name="Total Bugs"
          value={totalBugsReports}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdVerified} color={"orange"} />
              }
            />
          }
          name="Solicitudes de Verficación"
          value={summary.inReview}
        />
         <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdVerifiedUser} color={"green"} />
              }
            />
          }
          name="Usuarios Verificados"
          value={summary.verified}
        />
         <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdCancel} color={"red"} />
              }
            />
          }
          name="Usuarios Rechazados"
          value={summary.rejected}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdOutlineVerifiedUser} color={brandColor} />
              }
            />
          }
          name="Total Solicitudes"
          value={summary.total}
        />
      </SimpleGrid>
      
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
      <MiniCalendar />
      <Dailytraffic />
      
      </SimpleGrid>
      
    </Box>
    </Flex>
  );
}
