// Importa useState y useRef
import React, { useState, useRef, useEffect } from "react";
import {
  Flex,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useAuth } from "auth-context/auth.context";
import useAuth2fa from "variables/auth/useAuth2FA";
import { MdOutlineSafetyCheck } from "react-icons/md";

const Verify2FA = ({ num_document, secret_key }) => {
  const { setUser } = useAuth();
  const { recovery } = useAuth2fa();
  const history = useHistory();
  const [code, setCode] = useState(["", "", "", "", "", ""]); // Inicializa el código como un array
  const [email, setEmail] = useState(""); // Estado para el correo
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();
  const { verification } = useAuth2fa();
  const inputRefs = useRef([]);

  const coloricon = useColorModeValue("color.6", "color.6");
  const colortext = useColorModeValue("color.3", "white");
  const colorgb = useColorModeValue("white", "color.3");
  const colorInput = useColorModeValue("color.3", "white");

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  const handleCheckCode = async () => {
    try {
      const data = {
        num_document,
        secret_key,
        verification_code: code.join(""),
      }; // Convierte el array de código en un string
      const response = await verification(data);

      if (response.status === 200) {
        setProfile(response);
      } else {
        showToast("Error", response.message, "error");
      }
    } catch (error) {
      showToast("Error", "Error al verificar el código.", "error");
    }
  };

  const setProfile = (response) => {
    const user = response.data;
    const accessToken = response.data.access_token;

    const userWithToken = {
      ...user,
      token: accessToken,
      data: response.data,
    };
    setUser(userWithToken);
    localStorage.setItem("user", JSON.stringify(userWithToken));
    const expirationTime = Date.now() + 86400000;
    localStorage.setItem("expirationTime", expirationTime.toString());

    sessionStorage.setItem("loginTime", Date.now().toString());

    history.push("/dashboards");
  };

  const showToast = (title, description, status) => {
    toast({
      title,
      description,
      status,
      duration: 10000,
      isClosable: true,
    });
  };

  const handleInputChange = (index, value) => {
    // Solo permite caracteres numéricos
    if (/^\d*$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);
      if (value !== "" && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && code[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailSubmit = async () => {
    try {
      const data = { email: email };
      const response = await recovery(data);
      if (response.status === 200) {
        showToast(
          "Correo enviado",
          "Revisa tu bandeja de entrada para más instrucciones.",
          "success"
        );
        onClose();
        setEmail("");
        return response;
      } else {
        showToast("Error", response.message, "error");
      }
    } catch (error) {
      console.error("Error:", error.data);
      const errorMessage = error.data?.["error:"];

      showToast("Error", errorMessage, "error");
    }
  };

  return (
    <>
      <Flex align="center" justify="center" minH="100vh">
        <Box
          maxW="550px"
          p="6"
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="lg"
          bg={colorgb}
        >
          <Box
            display="flex"
            justifyContent="center"
            mx="auto"
            my={4}
            color={coloricon}
          >
            <MdOutlineSafetyCheck size={150} />
          </Box>
          <FormControl mb={4}>
            <FormLabel textAlign="center" mb={2}>
              Codigo de Verificación
            </FormLabel>
            <FormLabel
              textAlign="center"
              mb={4}
              fontSize="0.9rem"
              color={colortext}
            >
              Su código de verificación de 6 dígitos. Por favor ingréselo a
              continuación.
            </FormLabel>
            <Flex justify="space-between">
              {[0, 1, 2, 3, 4, 5].map((index) => (
                <React.Fragment key={index}>
                  {index === 3 && (
                    <Box
                      alignSelf="center"
                      marginLeft="4"
                      marginRight="4"
                      fontSize="6xl"
                      fontWeight="bold"
                    >
                      -
                    </Box>
                  )}
                  <Input
                    ref={(el) => (inputRefs.current[index] = el)}
                    type="text"
                    fontSize="6xl"
                    placeholder="-"
                    value={code[index]}
                    color={colorInput}
                    _placeholder={{ color: "color.4" }}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    maxLength={1}
                    w="13%"
                    h="auto"
                    textAlign="center"
                  />
                </React.Fragment>
              ))}
            </Flex>
          </FormControl>
          <Flex justifyContent="flex-end">
            <Button
              backgroundColor="transparent"
              onClick={onOpen}
              fontWeight="light"
              _hover={{ backgroundColor: "transparent" }}
              _active={{ color: "blue" }}
            >
              Recuperar Acceso ?
            </Button>
          </Flex>

          <Flex justifyContent="center">
            <Button
              variant="brand"
              onClick={handleCheckCode}
              w="50%"
              mt={4}
              mb={2}
            >
              Verificar
            </Button>
          </Flex>
        </Box>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent
          display="flex"
          alignItems="center"
          justifyContent="center"
          mx="auto"
          my="auto"
        >
          <ModalHeader textAlign="center">Recuperar Acceso</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Correo Electrónico</FormLabel>
              <Input
                color={colorInput}
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Ingresa tu correo electrónico"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleEmailSubmit}>
              Enviar
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Verify2FA;
