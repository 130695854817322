import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import { useAuth } from "auth-context/auth.context";
import coupons from "api/cupons";

const CuponModal = ({ isOpen, onClose }) => {
  const { user } = useAuth();
  
    //colors
    const textInput = useColorModeValue("color.3", "white");
    const bg = useColorModeValue("white", "color.3");
    const textColor = useColorModeValue("color.3", "white");
    const colorButton = useColorModeValue("color.6", "color.6");
    const colorButtonCancel = useColorModeValue("color.5", "color.5");
  
  
  
  const [formData, setFormData] = useState({
    name: "",
    expiration_date: "",
    coupon_active: false,
    description: "",
    points: "",
  });
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    const isFormValid = Object.values(formData).every((value) => value !== "");
    setFormValid(isFormValid);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue = type === "checkbox" ? checked : value;

    if (name === "description" && newValue.length > 200) {
      newValue = newValue.slice(0, 200);
    }

    setFormData({ ...formData, [name]: newValue });
  };

  const handleSubmit = async () => {
    try {
      await coupons.postCoupons(user.token, formData);
      onClose();
    } catch (error) {
      console.error("Error al registrar el cupón:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent bg={bg}>
        <ModalHeader color={textColor}>Registrar Nuevo Cupón</ModalHeader>
        <ModalCloseButton color={textColor} />
        <ModalBody>
          <FormControl mb={4} isRequired>
            <FormLabel color={textColor}>Nombre:</FormLabel>
            <Input
              type="text"
              name="name"
              color={textInput}
              value={formData.name}
              onChange={handleChange}
              variant="auth"
              _placeholder={{color:textInput}}
              placeholder="Ingrese el nombre del cupón"
            />
          </FormControl>
          <FormControl mb={4} isRequired>
            <FormLabel color={textColor}>Fecha de Expiración:</FormLabel>
            <Input
              type="date"
              name="expiration_date"
              value={formData.expiration_date}
              onChange={handleChange}
              variant="auth"
            />
          </FormControl>
          <FormControl mb={4} >
            <FormLabel color={textColor}>Activo:</FormLabel>
            <Checkbox
              name="coupon_active"
              isChecked={formData.coupon_active}
              onChange={handleChange}
              colorScheme="blue"
            />
          </FormControl>
          <FormControl mb={4} isRequired>
            <FormLabel color={textColor}>Descripción:</FormLabel>
            <Textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              color={textInput}
              _placeholder={{color:textInput}}
              resize="vertical"
              placeholder="Ingrese la descripción del cupón (máximo 200 caracteres)"
              maxLength={200}
            />
          </FormControl>
          <FormControl mb={4} isRequired>
            <FormLabel color={textColor}>Puntos:</FormLabel>
            <Input
              type="number"
              name="points"
              value={formData.points}
              color={textInput}
              _placeholder={{color:textInput}}
              onChange={handleChange}
              variant="auth"
              placeholder="Ingrese la cantidad de puntos"
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button bg={colorButton} color="white" mr={3} onClick={handleSubmit} disabled={!formValid}>
            Registrar
          </Button>
          <Button bg={colorButtonCancel} onClick={onClose}>Cancelar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CuponModal;
