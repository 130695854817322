import Kyc from "api/kyc";
import { useAuth } from "auth-context/auth.context";
import { useEffect, useState, useCallback } from "react";

export function useKyc() {
    const { user } = useAuth();
    const [listDocuments, setListDocuments] = useState([]);
    const [summary, setSummary] = useState({
        total: 0,
        verified: 0,
        rejected: 0,
        inReview: 0
    });

    const fetchDocuments = useCallback(async () => {
        if (user && user.token) {
            try {
                const response = await Kyc.listDocument(user.token);
                setListDocuments(response.data);
                calculateSummary(response.data);
            } catch (error) {
                console.error("Error fetching list Documents", error);
            }
        }
    }, [user]);

    useEffect(() => {
        fetchDocuments();
    }, [user, fetchDocuments]);

    const calculateSummary = (documents) => {
        const total = documents.length;
        const verified = documents.filter(doc => doc.status === "Aceptado").length;
        const rejected = documents.filter(doc => doc.status === "Rechazado").length;
        const inReview = documents.filter(doc => doc.status === "En revisión").length;

        setSummary({
            total,
            verified,
            rejected,
            inReview
        });
    };

    const acceptedUser = async (id, data) => {
        try {
            await Kyc.acceptedUser(user.token, id, data);
            fetchDocuments();
        } catch (error) {
            console.error("Error al aceptar usuario", error);
        }
    }

    const rejectedUser = async (id, data) => {
        try {
            await Kyc.rejectedUser(user.token, id, data);
            fetchDocuments();
        } catch (error) {
            console.error("Error al rechazar usuario", error);
        }
    }

    return {
        listDocuments,
        acceptedUser,
        rejectedUser,
        summary,
        fetchDocuments
    }
}
