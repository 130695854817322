import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../auth-context/auth.context";

export const ProtectedRoute = ({ ...rest }) => {
  const { user } = useAuth();

  // Verificar si el usuario está autenticado
  const isAuthenticated = user && user.token && user.token !== "";

  // Si el usuario no está autenticado, redirigir a la página de inicio de sesión
  if (!isAuthenticated) {
    return <Redirect to="/auth/sign-in" />;
  }

  // Si el usuario está autenticado, renderizar la ruta protegida
  return <Route {...rest} />;
};
