import React, { Component } from "react";
import Chart from "react-apexcharts";

class ColumnChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
      chartOptions: {},
    };
  }

  componentDidMount() {
    this.setState({
      chartData: this.props.chartData,
      chartOptions: {
        ...this.props.chartOptions,
        xaxis: {
          ...this.props.chartOptions.xaxis,
          categories: this.props.chartOptions.xaxis.categories || [], // Asegurar que las categorías estén definidas
        },
      },
    });
  }

  componentDidUpdate(prevProps) {
    // Actualizar el estado si cambian las props
    if (prevProps.chartData !== this.props.chartData || prevProps.chartOptions !== this.props.chartOptions) {
      this.setState({
        chartData: this.props.chartData,
        chartOptions: {
          ...this.props.chartOptions,
          xaxis: {
            ...this.props.chartOptions.xaxis,
            categories: this.props.chartOptions.xaxis.categories || [], // Asegurar que las categorías estén definidas
          },
        },
      });
    }
  }

  render() {
    return (
      <Chart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type='bar'
        width='100%'
        height='100%'
      />
    );
  }
}

export default ColumnChart;
