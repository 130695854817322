import axios from "api";

const base = "users";

const Kyc = {

    listDocument:async  (token) => {
        return axios.get(`${base}/list-documents`,{
            headers: {
            Authorization: `Bearer ${token}`,
            }
        })
    },
    acceptedUser: async (token,id,data) =>{
        return await axios.post(`${base}/accepted-user/${id}`,data,{
            headers:{
                Authorization: `Bearer ${token}`,
            }
        })
    },
    rejectedUser: async (token,id,data) =>{
        return await axios.post(`${base}/rejected-user/${id}`,data,{
            headers:{
                Authorization: `Bearer ${token}`,
            }
        })
    },
    
    recoveryAcces:async (data) => {
        try {
            return await axios.post(`${base}/change-user-auth`, data);

        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

}

export default Kyc