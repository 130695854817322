import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Image,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  TableContainer,
  useColorModeValue,
  Input,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useKyc } from "variables/useKyc";

const KYC = () => {
  const { listDocuments, fetchDocuments, acceptedUser, rejectedUser } = useKyc();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [reason, setReason] = useState("");
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);

  const borderColor = useColorModeValue("color.5", "color.3");
  const bgBox = useColorModeValue("white", "color.3");
  const bg = useColorModeValue("white", "color.3");
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const submissionsInReview = listDocuments.filter(
    (submission) => submission.status === "En revisión"
  );

  const openModal = (submission) => {
    setSelectedSubmission(submission);
    onOpen();
  };

  const handleApprove = async () => {
    setIsLoadingApprove(true);
    const data = { status: "Aceptado" };
    if (selectedSubmission) {
      try {
        await acceptedUser(selectedSubmission.user.id, data);
        setSelectedSubmission(null);
        fetchDocuments();
        onClose();
      } catch (error) {
        console.error("Error al aceptar el Usuario:", error);
      } finally {
        setIsLoadingApprove(false);
      }
    }
  };

  const handleReject = async () => {
    setIsLoadingReject(true);
    const data = { status: "Rechazado", reason: reason };
    if (selectedSubmission) {
      try {
        await rejectedUser(selectedSubmission.user.id, data);
        setSelectedSubmission(null);
        setReason("");
        fetchDocuments();
        onClose();
      } catch (error) {
        console.error("Error al rechazar el Usuario:", error);
      } finally {
        setIsLoadingReject(false);
      }
    }
  };

  if (!listDocuments) {
    return <Text>Loading...</Text>;
  }

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Box
        bg={bgBox}
        borderRadius="10px"
        border="1px"
        borderColor={borderColor}
        p="4"
        overflowY="hidden"
        overflowX="auto"
      >
        {submissionsInReview.length === 0 ? (
          <Text>No hay envíos para verificar</Text>
        ) : (
          <TableContainer>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Nombre</Th>
                  <Th>Num Documento</Th>
                  <Th>Fecha</Th>
                  <Th>Estado</Th>
                </Tr>
              </Thead>
              <Tbody>
                {submissionsInReview.map((submission) => (
                  <Tr
                    key={submission.id}
                    onClick={() => openModal(submission)}
                    cursor="pointer"
                  >
                    <Td>{submission.id}</Td>
                    <Td>{submission.user.full_name}</Td>
                    <Td>{submission.user.num_document}</Td>
                    <Td>
                      {new Date(submission.created_at).toLocaleDateString(
                        "es-ES",
                        { day: "numeric", month: "short", year: "numeric" }
                      )}
                    </Td>
                    <Td>{submission.status}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Box>

      <Modal isOpen={isOpen}  onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent p={10} alignItems="center" bg={bg} maxW="100%">
          <ModalHeader>Detalles del Usuario</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedSubmission && (
              <Box >
                <Text fontSize="xl" mb={2}>
                  Foto del documento
                </Text>
                {isMobile ? (
                  <>
                    <Box mb={4}>
                      <Image
                        border="1px solid gray"
                        src={selectedSubmission.document_evidence}
                        alt="Document Photo"
                        w="90%"
                      />
                    </Box>
                    {selectedSubmission.document_evidence2 && (
                      <Box>
                        <Image
                          border="1px solid gray"
                          src={selectedSubmission.document_evidence2}
                          alt="Document Photo"
                          w="90%"
                          
                        />
                      </Box>
                    )}
                  </>
                ) : (
                  <Box display="flex" paddingBottom="10px">
                    <Image
                      border="1px solid gray"
                      src={selectedSubmission.document_evidence}
                      alt="Document Photo"
                      flex="1"
                      mr={2}
                      w="50%"
                      h="500px"
                    />
                    {selectedSubmission.document_evidence2 && (
                      <Image
                        border="1px solid gray"
                        src={selectedSubmission.document_evidence2}
                        alt="Document Photo"
                        flex="1"
                        ml={2}
                        w="50%"
                        h="500px"
                      />
                    )}
                  </Box>
                )}
                <Text>ID: {selectedSubmission.id}</Text>
                <Text>País: {selectedSubmission.user.country.country}</Text>
                <Text>Nombre: {selectedSubmission.user.full_name}</Text>
                <Text>Num. Documento: {selectedSubmission.user.num_document}</Text>
                <Text mt={4}>Razón del rechazo:</Text>
                <Input
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </Box>
            )}
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button
              variant="brand"
              bg={"color.6"}
              onClick={handleApprove}
              isLoading={isLoadingApprove}
              isDisabled={isLoadingReject}
            >
              Aprobar
            </Button>
            <Button
              variant="brand"
              marginLeft="5"
              bg="color.6"
              onClick={handleReject}
              isLoading={isLoadingReject}
              isDisabled={isLoadingApprove}
            >
              Rechazar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default KYC;
