import AuthApi from "api/auth";
import Kyc from "api/kyc";

export default function useAuth2fa() {
  const login = async (data) => {
    try {
      const response = await AuthApi.Login(data);
      return response; // Devuelve la respuesta para que puedas acceder a ella
    } catch (err) {
      console.error("Ha ocurrido un error en el inicio de sesión.", err);
      throw err; // Re-lanza el error para que puedas manejarlo donde llamas a login
    }
  };

  const verification = async (data) => {
    try {
      const response = await AuthApi.Verify(data);
      return response; 
    } catch (error) {
      console.error("Ha ocurrido un error en la verificación.", error);
      throw error; // Re-lanza el error para que puedas manejarlo donde llamas a verification
    }
  };

  const recovery = async (data) => {
    try {
      const response = await Kyc.recoveryAcces(data);
      return response
    } catch (error) {
      console.error("Ha ocurrido un error al recuparar el acceso", error.response.data);
      throw error.response; 
    }
  }

  return { login, verification, recovery };
}
