let BACKEND_SERVER = null;
if (process.env.REACT_APP_BACKEND_SERVER) {
  BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
} else {
  BACKEND_SERVER = "http://localhost:5000/api/";
}

export const accountSid = process.env.REACT_APP_TWILIO_ACCOUNT_SID;
export const authToken = process.env.REACT_APP_TWILIO_AUTH_TOKEN;

export const API_SERVER = BACKEND_SERVER;