import React, { useState, useEffect, useRef } from "react";
import {
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Select,
  Icon,
  useColorModeValue,
  FormLabel,
  IconButton,
  Text,
  Box,
  Img,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import {
  FaCheckCircle,
  FaChevronLeft,
  FaChevronRight,
  FaDownload,
  FaEye,
  FaTimesCircle,
} from "react-icons/fa";
import { useAuth } from "auth-context/auth.context";
import coupons from "api/cupons";
import CouponsMenu from "components/menu/CouponsMenu";
import ReactFileDownload from 'react-file-download'

const ITEMS_PER_PAGE = 10;

const TablaCupons = (props) => {
  const { user } = useAuth();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedQRCode, setSelectedQRCode] = useState(null); 
  const { columnsData, tableData } = props;
  const sortColumn = useRef("id"); // Columna por la cual ordenar, por defecto 'id'
  const sortOrder = useRef("desc"); // Orden por defecto 'desc'

  //colors
  const TextColumn = useColorModeValue("color.6", "color.6");
  const textInput = useColorModeValue("color.3", "white");
  const bg = useColorModeValue("white", "color.3");
  const bgmodal = useColorModeValue("color.7", "color.3");
  const textColor = useColorModeValue("color.3", "white");
  const colorButton = useColorModeValue("color.6", "color.6")

 
  useEffect(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const sortedData = [...tableData].sort((a, b) => {
      if (sortOrder.current === "asc") {
        return a[sortColumn.current] - b[sortColumn.current];
      } else {
        return b[sortColumn.current] - a[sortColumn.current];
      }
    });
    setData(sortedData.slice(startIndex, endIndex));
  }, [tableData, currentPage, sortColumn, sortOrder]);

  const handleStateChange = async (couponId, rowIndex, newValue) => {
    const confirmation = window.confirm(
      "¿Estás seguro de cambiar el estado del Cupon?"
    );
    if (!confirmation) return;
    try {
      await coupons.updateStatusCoupon(user.token, couponId);
      const newData = [...data];
      newData[rowIndex].status = newValue;
      setData(newData);
    } catch (error) {
      console.error("Error al cambiar el estado del Cupon:", error);
    }
  };

  const handleSort = (column) => {
    sortColumn.current = column;
    sortOrder.current = sortOrder.current === "asc" ? "desc" : "asc";
    setCurrentPage(1); // Reiniciar a la primera página cuando se cambia la columna de ordenamiento
  };

  const previousPage = () => {
    setCurrentPage((page) => Math.max(1, page - 1));
  };

  const nextPage = () => {
    setCurrentPage((page) =>
      Math.min(page + 1, Math.ceil(tableData.length / ITEMS_PER_PAGE))
    );
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = Math.min(startIndex + ITEMS_PER_PAGE, tableData.length);

  return (
    <Flex direction="column" w="100%" h="100%" overflowX="auto">
      <Flex px="25px" justify="flex-end" mb="20px" align="flex-end">
        <Flex justifyContent="center">
          <FormLabel color="color.6">Agregar</FormLabel>
          <CouponsMenu/>
        </Flex>
      </Flex>

      <Box 
      bg={bg} 
      overflowY="hidden"
        overflowX="auto"  p="4" borderRadius="md" > 
      <Table variant="striped" color={textInput}>
        <Thead>
          <Tr>
            {columnsData.map((column, index) => (
              <Th
                key={index}
                bg={bg}
                color={TextColumn}
                onClick={() => handleSort(column.accessor)}
              >
                {column.Header}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row, rowIndex) => (
            <Tr key={rowIndex}>
              {columnsData.map((column, cellIndex) => (
                <Td bg={bg} key={cellIndex}>
                  {column.accessor === "status" ? (
                    <Flex align="center">
                      <Select
                        value={row[column.accessor]}
                        onChange={(e) =>
                          handleStateChange(row.id, rowIndex, e.target.value)
                        }
                        width="130px"
                      >
                        <option value="activo">Activo</option>
                        <option value="inactivo">Inactivo</option>
                      </Select>
                      {row[column.accessor] === "activo" ? (
                        <Icon as={FaCheckCircle} color="green.500" ml="2" />
                      ) : (
                        <Icon as={FaTimesCircle} color="red.500" ml="2" />
                      )}
                    </Flex>
                  ) : column.accessor === "qr_code_path" ? ( // Verifica si el accessor es "qr_code_path"
                  <Icon as={FaEye} color={textInput} cursor="pointer" onClick={() => {
                    setSelectedQRCode(row[column.accessor]);
                    setIsModalOpen(true);
                  }} />
                ): (
                    row[column.accessor]
                  )}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
      </Box>

      <Flex justify="center" align="center" px="25px" padding="5">
        <IconButton
          icon={<FaChevronLeft />}
          disabled={currentPage === 1}
          onClick={previousPage}
        />
        <Text color={textColor}>
          Página {currentPage} de {Math.ceil(tableData.length / ITEMS_PER_PAGE)}
        </Text>
        <IconButton
          icon={<FaChevronRight />}
          disabled={endIndex >= tableData.length}
          onClick={nextPage}
        />
      </Flex>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
  <ModalOverlay />
  <ModalContent bg={bgmodal}> 
    <ModalHeader textAlign="center">Código QR</ModalHeader>
    <ModalCloseButton />
    <ModalBody display="flex" justifyContent="center">
      {selectedQRCode && <Img src={"https://" + selectedQRCode} alt="QR Code" />}
    </ModalBody>
    <ModalFooter justifyContent="center">
    <Button
     variant="brand"
    leftIcon={<FaDownload />}
   bg={colorButton}
   color="white"
    onClick={() => {
      ReactFileDownload(
        "https://" + selectedQRCode,
        "codigo_qr.png" 
      );
    }}
  >
    Descargar
  </Button>
    </ModalFooter>
  </ModalContent>
</Modal>

    </Flex>
    
  );
};

export default TablaCupons;
