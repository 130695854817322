import axios from "api";

const GetCategory ={
    getCategory:(token) => {
        return  axios.get(`${base}/categories-create-read` , {
            headers: {
                Authorization: `Bearer ${token} `
            }
        });
    },
    postCategory: (token, data) => {
   
        return axios.post(`${base}/categories-create-read`, data, {
          headers: {
            Authorization: `Bearer ${token}`
          }
          
        });
      }
}

let  base ="products"

export default GetCategory