import React, { useEffect, useState } from "react";
import {
  Box,
  FormLabel,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from "@chakra-ui/react";
import TablaCupons from "./components/TablaCupons"; // Añadido
import TablaCouponsExchanges from "./components/CouponsExchanges";
import Getusers from "api/users";
import { useAuth } from "auth-context/auth.context"; // Añadido
import coupons from "api/cupons"; // Añadido

export default function Cupons(props) {
  const { user } = useAuth();
  const [couponsData, setCouponsData] = useState([]);
  const [redeemedCouponsData, setRedeemedCouponsData] = useState([]);
  const [exchangeData, setExchangeData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [userIdToSend, setUserIdToSend] = useState();

  //colors
  const textColorInput = useColorModeValue("secondaryGray.900", "white");
  const textColorTabs = useColorModeValue("color.6", "color.6");


  useEffect(() => {
    let isMounted = true; // Variable para rastrear si el componente está montado
  
    const fetchCouponsData = async () => {
      try {
        if (user && user.token && isMounted) {
          const response = await coupons.getCoupons(user.token);
          const formattedData = response.data.map((coupon) => ({
            id: coupon.id,
            name: coupon.name,
            expiration_date: coupon.expiration_date,
            status: coupon.coupon_active ? "activo" : "inactivo",
            description: coupon.description,
            value: coupon.points,
            code: coupon.coupon_code,
            qr_code_path: coupon.qr_code_path
          }));
          
          if (isMounted) {
            setCouponsData(formattedData);
  
            const redeemedCoupons = formattedData.filter(
              (coupon) => coupon.status === "inactivo"
            );
            setRedeemedCouponsData(redeemedCoupons);
          }
        }
      } catch (error) {
        console.error("Error al obtener cupones:", error);
      }
    };
  
    fetchCouponsData();
  
    return () => {
      isMounted = false; // Actualiza el estado de montaje a falso al desmontar
    };
  }, [user, redeemedCouponsData]);
  

  useEffect(() => {

    const fetchExchangeCouponsData = async () => {
        try {
            if (user && user.token) {
                const params = {
                    users: userIdToSend,
                };
                const response = await coupons.getExchangeCoupons(user.token, params);

                const userResponse = await Getusers.getUsers(user.token);
                setUsersData(userResponse.data);

                const formattedExchangeData = formatExchangeData(
                    response.data,
                    userResponse.data,
                    user.id,
                    setUserIdToSend
                );

                setExchangeData(formattedExchangeData);
            }
        } catch (error) {
            console.error("Error fetching ExchangeCoupons:", error);
        }
    };
    fetchExchangeCouponsData();
   
}, [user, userIdToSend, setUserIdToSend]);


  const formatExchangeData = (exchangeData, usersData, userId, setUserIdToSend) => {
    let userIdToSend = null;
    const formattedData = exchangeData.map((exchange) => {
        const foundUser = usersData.find(
            (userData) => userData.id === exchange.users
        );

        const userName = foundUser ? foundUser.full_name : "Usuario Desconocido";

        if (foundUser && foundUser.num_document === userId) {
            userIdToSend = foundUser.id;
        }

        return {
            id: exchange.id,
            name: exchange.coupons.name,
            code: exchange.coupons.coupon_code,
            description: exchange.coupons.description,
            user: userName,
        };
    });

    if (userIdToSend !== null) {
        setUserIdToSend(userIdToSend);
    }

    return formattedData;
};

  const columns = [
    { Header: "ID", accessor: "id" },
    { Header: "Nombre", accessor: "name" },
    { Header: "F. Vencimiento", accessor: "expiration_date" },
    { Header: "Estado", accessor: "status" },
    { Header: "Descripción", accessor: "description" },
    { Header: "Valor", accessor: "value" },
    { Header: "Codigo", accessor: "code" },
    { Header: "Codigo QR", accessor: "qr_code_path" },
  ];

  const ExchangeColumns = [
    { Header: "ID", accessor: "id" },
    { Header: "Cupon", accessor: "name" },
    { Header: "Codigo", accessor: "code" },
    { Header: "Descripción", accessor: "description" },
    { Header: "Usuario", accessor: "user" },
  ];

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
  
    // Si el input está vacío, mostrar todos los datos
    if (value === "") {
      setUserIdToSend(undefined);
    } else {
      // Buscar en usersData
      const foundUser = usersData.find(user => user.num_document === value);
  
      // Si se encuentra un usuario con el num_document ingresado, se actualiza userIdToSend
      if (foundUser) {
        setUserIdToSend(foundUser.id);
      }
    }
  };
  
 

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Tabs variant="enclosed" colorScheme={textColorTabs}>
        <TabList>
          <Tab color={textColorTabs}>Cupones de Premio</Tab>
          <Tab color={textColorTabs}>Cupones Canjeados</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            
              <TablaCupons columnsData={columns} tableData={couponsData} />
         
          </TabPanel>
          <TabPanel>
            
              <Box mb="20px" width="30%" marginLeft="30px">
                <FormLabel>Ingrese el numero Documento</FormLabel>
                <Input
                color={textColorInput}
                  placeholder="Buscar cupón..."
                  value={searchValue}
                  onChange={handleInputChange}
                  _placeholder={{color:textColorInput}}
                />
              </Box>
             
              <TablaCouponsExchanges
                columnsDataChange={ExchangeColumns}
                tableDataChange={exchangeData}
              />
           
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
