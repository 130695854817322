import React from "react";
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

export function SearchBar(props) {
  const {
    onChange,
    onSearch,
    placeholder,
    borderRadius,
    searchTerm,
    ...rest
  } = props;

  const searchIconColor = useColorModeValue("gray.700", "white");
  const inputBg = useColorModeValue("#FFFFFFF", "color.2");
  const inputText = useColorModeValue("gray.700", "gray.100");

  return (
    <InputGroup w={{ base: "100%", md: "100%" }} {...rest}>
      <InputLeftElement
        children={
          <IconButton
            bg="inherit"
            borderRadius="inherit"
            _hover={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
            onClick={onSearch}
            
            icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
          />
        }
      />
      <Input
        variant="search"
        fontSize="sm"
        border="1px"
        bg={inputBg}
        color={inputText}
        fontWeight="500"
        _placeholder={{ color: "gray.200", fontSize: "14px" }}
        borderRadius={borderRadius ? borderRadius : "30px"}
        placeholder={placeholder ? placeholder : "Search..."}
        value={searchTerm}
        onChange={onChange}
      />
    </InputGroup>
  );
}
