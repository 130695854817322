import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import AllTransactions from "./components/AllTransactions";
import FilterTransactions from "./components/FilterTransactions";

const MainComponent = () => {
  //colors
  const textColorTabs = useColorModeValue("color.6", "color.6");

  return (
    <Flex  maxWidth="100%" direction="column" >
      <Tabs
       colorScheme={textColorTabs}
        size="md"
        variant="enclosed"
        pt={{ base: "180px", md: "80px", xl: "80px" }}
      >
        <TabList>
          <Tab color={textColorTabs}> Todas Las Transacciones</Tab>
          <Tab color={textColorTabs}> Buscar transacción</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <AllTransactions />
          </TabPanel>
          <TabPanel>
            <FilterTransactions />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default MainComponent;
