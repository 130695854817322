// Chakra imports
import { Box, Divider, Flex, Grid, Image, Text } from "@chakra-ui/react";
import { FaUser, FaInfoCircle } from "react-icons/fa";
// Custom components
import Banner from "views/admin/profile/components/Banner";
// Assets
import banner from "assets/img/auth/banner.png";
import React, {useMemo } from "react";
import { useAuth } from "auth-context/auth.context";
import { createAvatar } from "@dicebear/core";
import { bottts,  } from "@dicebear/collection";

export default function UserProfileForm() {
  const { user } = useAuth();
  const {
    id,
    full_name,
    num_document,
    email,
    points,
    public_key,
    private_key,
    address,
    user_admin,
    qr_code_path,
    reference_code,
  } = user.data;


  // Generar el avatar aleatorio usando useMemo
  const randomAvatar = useMemo(() => {
    return createAvatar(bottts, {
      size: 128,
      // Puedes agregar más opciones aquí
    }).toDataUriSync();
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={8} mt={4}>
        <Box p={6} borderRadius="lg" boxShadow="base" mb={{ base: 8, md: 0 }}>
          <Banner
            gridArea="1 / 1 / 1 / 1"
            banner={banner}
            avatar={randomAvatar}  // Utiliza el avatar aleatorio
            name={full_name}
            job={user_admin ? "Admin" : "User"}
            posts={points}
          />
          <Box display="flex" alignItems="center" mb={4}>
            <FaUser
              fontSize="20px"
              color="blue.500"
              style={{ marginRight: "8px" }}
            />
            <Text
              style={{ marginBottom: "8px", marginTop: "8px" }}
              fontWeight="bold"
              fontSize="lg"
            >
              Información Básica{" "}
            </Text>
          </Box>
          <Divider />
          <Text
            style={{ marginBottom: "8px", marginTop: "8px" }}
            fontWeight="bold"
          >
            ID:
          </Text>
          <Text>{id}</Text>
          <Divider orientation="horizontal" />
          <Text
            style={{ marginBottom: "8px", marginTop: "8px" }}
            fontWeight="bold"
          >
            Nombre Completo:
          </Text>
          <Text>{full_name}</Text>
          <Divider orientation="horizontal" />
          <Text
            style={{ marginBottom: "8px", marginTop: "8px" }}
            fontWeight="bold"
          >
            Número de Documento:
          </Text>
          <Text>{num_document}</Text>
          <Divider orientation="horizontal" />
          <Text
            style={{ marginBottom: "8px", marginTop: "8px" }}
            fontWeight="bold"
          >
            Correo Electrónico:
          </Text>
          <Text>{email}</Text>
        </Box>
        <Box p={6} borderRadius="lg" boxShadow="base">
          <Box display="flex" alignItems="center" mb={4}>
            <FaInfoCircle
              fontSize="20px"
              color="green.500"
              style={{ marginRight: "8px" }}
            />
            <Text
              style={{ marginBottom: "8px", marginTop: "8px" }}
              fontWeight="bold"
              fontSize="lg"
            >
              {" "}
              Información Adicional
            </Text>
          </Box>
          <Divider />
          <Text fontWeight="bold">Dirección:</Text>
          <Text>{address || "No proporcionada"}</Text>
          <Divider orientation="horizontal" />
          <Text fontWeight="bold">¿Usuario Administrador?</Text>
          <Text>{user_admin ? "Sí" : "No"}</Text>
          <Divider orientation="horizontal" />
          <Text fontWeight="bold">Código de Referencia:</Text>
          <Text>{reference_code}</Text>
          <Divider orientation="horizontal" />
          <Text fontWeight="bold">Puntos:</Text>
          <Text>{points}</Text>
          <Divider orientation="horizontal" />
          <Text
            style={{ marginBottom: "8px", marginTop: "8px" }}
            fontWeight="bold"
          >
            Clave Pública:
          </Text>
          <Text>{public_key}</Text>
          <Divider orientation="horizontal" />
          <Text
            style={{ marginBottom: "8px", marginTop: "8px" }}
            fontWeight="bold"
          >
            Clave Privada:
          </Text>
          <Text>{private_key}</Text>
          <Divider orientation="horizontal" />
          <Flex direction="column" alignItems="center">
            <Text
              style={{ marginBottom: "8px", marginTop: "8px" }}
              fontWeight="bold"
            >
              Código QR:
            </Text>
            <Image src={"https://" + qr_code_path} />
          </Flex>
        </Box>
      </Grid>
    </Box>
  );
}
