import React, { useState, useMemo, useEffect } from "react";
import {
  Flex,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Text,
  useColorModeValue,
  Icon,
  Td,
  Box,
  Input,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Tooltip,
  IconButton,
  Image,
} from "@chakra-ui/react";
import { MdCheckCircle, MdDone, MdHourglassFull } from "react-icons/md";

import useExchangeProducts from "variables/useExchangeProducts";
import { FaChevronLeft, FaChevronRight, FaEye } from "react-icons/fa";

function TablaCompras(props) {
  const { transactionsData } = useExchangeProducts();
  const { updateState } = useExchangeProducts();
  const { columnsData, tableData, onFilterChange } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const [data, setData] = useState([]);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 15;
  //colors
  const textColor = useColorModeValue("navy.700", "white");
  const textInput = useColorModeValue("color.3", "white");
  const bg = useColorModeValue("white", "color.3");
  const textColortable = useColorModeValue("color.3", "white");
  const bgth = useColorModeValue("#FFFFFF", "color.3");

  const previousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  // Función para manejar el cambio a la página siguiente
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    setData(tableData);
  }, [tableData, columns]);

  const handleStatusChange = async (rowIndex, e) => {
    const newData = [...data];
    const id = newData[rowIndex].id;
    const newStatusName = e.target.value;

    const statusMap = {
      "en proceso": 1,
      enviado: 2,
      recibido: 3,
    };

    const newStatusId = statusMap[newStatusName];

    try {
      await updateState(id, { new_status_id: newStatusId });
      newData[rowIndex].status = newStatusName;
      setData(newData);
    } catch (error) {
      console.error("Error updating estado:", error);
    }
  };

  const handleOpenDetails = (purchase) => {
    const { id } = purchase;
    const rawPurchase = transactionsData.find((t) => t.id === id);
    setSelectedPurchase({ ...rawPurchase, id });
  };

  const handleCloseDetails = () => {
    setSelectedPurchase(null);
  };

  // Calcular el índice del primer elemento en la página actual
  const startIndex = currentPage * itemsPerPage;
  // Calcular el índice del último elemento en la página actual
  const endIndex = Math.min(startIndex + itemsPerPage, data.length);
  return (
    <>
      <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
        <Flex px="25px" justifyContent="space-between" mb="20px" align="center">
          <Text color={textColor} fontSize="xl" fontWeight="600">
            Filtrar por :
          </Text>
          <Input
            type="text"
            _placeholder={{ color: textInput }}
            color={textInput}
            onChange={(e) => onFilterChange(e.target.value)}
            placeholder="Número de documento o Nombre"
            maxW="300px"
          />
        </Flex>
      </Box>
      <Box
        p="4"
        borderRadius="10px"
        bg={bg}
        overflowY="hidden"
        overflowX="auto"
      >
        <Table variant="striped">
          <Thead>
            <Tr>
              {columnsData.map((column, index) => (
                <Th pe="10px" key={index}>
                  {column.Header}
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody>
            {data.slice(startIndex, endIndex).map((row, rowIndex) => (
              <Tr key={rowIndex}>
                {columnsData.map((column, cellIndex) => (
                  <Td
                    bg={bgth}
                    key={cellIndex}
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  >
                    {column.accessor === "id" ? (
                      <Flex align="center">
                        <Tooltip label="Ver detalles">
                          <IconButton
                            width="10px"
                            height="10px"
                            color={textColortable}
                            aria-label="Ver detalles"
                            icon={<FaEye />}
                            variant="ghost"
                            onClick={() => handleOpenDetails(row)}
                          />
                        </Tooltip>
                      </Flex>
                    ) : column.accessor === "status" ? (
                      <Flex align="center">
                        <Select
                          value={row[column.accessor]}
                          onChange={(e) => handleStatusChange(rowIndex, e)}
                          width="135px"
                        >
                          <option value="en proceso">En Proceso</option>
                          <option value="enviado">Enviado</option>
                          <option value="recibido">Recibido</option>
                        </Select>
                        {row[column.accessor] === "en proceso" ? (
                          <Icon as={MdHourglassFull} color="red.500" ml="2" />
                        ) : row[column.accessor] === "enviado" ? (
                          <Icon as={MdCheckCircle} color="orange.500" ml="2" />
                        ) : (
                          <Icon as={MdDone} color="green.500" ml="2" />
                        )}
                      </Flex>
                    ) : (
                      row[column.accessor]
                    )}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Modal isOpen={selectedPurchase !== null} onClose={handleCloseDetails}>
        <ModalOverlay />
        <ModalContent bg={bg}>
          <ModalHeader>Detalles de la compra</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedPurchase && (
              <Box>
                <Text>
                  <b>ID: </b> {selectedPurchase.id}
                </Text>
                <Text>
                  <b>Estado: </b> {selectedPurchase.status}
                </Text>
                <Text>
                  <b>Fecha de creación: </b>{" "}
                  {new Date(selectedPurchase.created_at).toLocaleString()}
                </Text>
                <Text>
                  <b>Fecha de actualización: </b>{" "}
                  {new Date(selectedPurchase.updated_at).toLocaleString()}
                </Text>
                <Text>
                  <b>Usuario: </b> {selectedPurchase.user}
                </Text>
                <Text>
                  <b>Detalles del producto: </b>{" "}
                </Text>
                <Text>
                  <b>ID: </b> {selectedPurchase.productos.id}
                </Text>
                <Text>
                  <b>Nombre: </b> {selectedPurchase.productos.name}
                </Text>
                <Text>
                  <b>Stock: </b> {selectedPurchase.productos.stock}
                </Text>
                <Text>
                  <b>Puntos de precio: </b>{" "}
                  {selectedPurchase.productos.price_points}
                </Text>
                <Text>
                  <b>Activo: </b>{" "}
                  {selectedPurchase.productos.active ? "Sí" : "No"}
                </Text>
                <Text>
                  <b>Fecha de creación del producto: </b>{" "}
                  {new Date(
                    selectedPurchase.productos.created_at
                  ).toLocaleString()}
                </Text>
                <Text>
                  <b>Fecha de actualización del producto: </b>{" "}
                  {new Date(
                    selectedPurchase.productos.updated_at
                  ).toLocaleString()}
                </Text>
                <Text>
                  <b>Código: </b> {selectedPurchase.productos.code}
                </Text>
                <Text>
                  <b>Descripción: </b> {selectedPurchase.productos.description}
                </Text>
                <Text>
                  <b>Oferta: </b>{" "}
                  {selectedPurchase.productos.oferta ? "Sí" : "No"}
                </Text>
                <Text>
                  <b>Categoría: </b> {selectedPurchase.productos.category}
                </Text>
                <Text>
                  <b>Imagen: </b>{" "}
                </Text>
                <Image
                  src={"https://" + selectedPurchase.productos.imagen_product}
                />
              </Box>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Flex justify="center" align="center" px="25px" padding="5">
        <IconButton
          icon={<FaChevronLeft />}
          disabled={currentPage === 0}
          onClick={previousPage}
        />
        <Text color={textColor}>
          Página {currentPage + 1} de {Math.ceil(data.length / itemsPerPage)}
        </Text>
        <IconButton
          icon={<FaChevronRight />}
          disabled={endIndex >= data.length}
          onClick={nextPage}
        />
      </Flex>
    </>
  );
}

export default TablaCompras;
