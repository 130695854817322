import { useAuth } from "auth-context/auth.context";
import { useEffect, useState, useCallback } from "react";
import points from "api/points";

export default function usePoints() {
  const { user } = useAuth();
  const [pointsCompany, setPointsCompany] = useState("");

  const fetchPointsData = useCallback(async () => {
    try {
      const response = await points.getPointsCompany(user.token);
      setPointsCompany(response.data.total_points);
    } catch (error) {
      console.error("Error fetching points company:", error);
    }
  }, [user]);

  useEffect(() => {
    if (user.token) {
      fetchPointsData();
    }
  }, [user, fetchPointsData]);

  const updatePointsCompany = async (data) => {
    try {
      await points.updatePointsCompany(user.token, data);
      // Después de actualizar, volver a obtener los datos de puntos
      fetchPointsData();
    } catch (error) {
      console.error("Error al actualizar los puntos de la compañia:", error);
    }
  };

  return {
    pointsCompany,
    updatePointsCompany,
  };
}
