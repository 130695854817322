import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { useAuth } from "auth-context/auth.context";
import GetCategory from "api/category";

export default function CategoryModal({ isOpen = false, onClose = () => {} }) {
  const { user } = useAuth();
  const toast = useToast();

  const [categoryName, setCategoryName] = useState("");
  const bg = useColorModeValue("white", "color.3");

  const enviarCategoria = async () => {
    try {
      if (user && user.token) {
        // Verificar si ya existe una categoría con el mismo nombre
        const existingCategoryResponse = await GetCategory.getCategory(user.token);
        const existingCategory = existingCategoryResponse.data.find(category => category.category_name === categoryName);


        // Si existe una categoría con el mismo nombre, mostrar un toast de error y salir de la función
        if (existingCategory) {
          toast({
            title: "Ya existe una categoría con ese nombre.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }

        // Si no existe una categoría con el mismo nombre, guardarla
        await GetCategory.postCategory(user.token, {
          category_name: categoryName,
        });

        // Mostrar un toast de éxito cuando la categoría se guarda correctamente
        toast({
          title: "Categoría guardada.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        onClose();
        setCategoryName(""); // Limpiar el campo de nombre de categoría
      }
    } catch (error) {
      console.error("Error al enviar la categoría:", error);
      // Mostrar un toast de error cuando ocurra un error al guardar la categoría
      toast({
        title: "Error al guardar la categoría.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg={bg}>
        <ModalHeader>Enviar Categoría</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="categoryName">
            <FormLabel>Nombre de la Categoría</FormLabel>
            <Input
              variant="auth"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              placeholder="Ingrese el nombre de la categoría"
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="brand" bg={"color.6"} mr={3} onClick={onClose}>
            Cerrar
          </Button>
          <Button variant="brand" bg={"color.6"}  onClick={enviarCategoria}>
            Enviar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
