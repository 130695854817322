import React, { useState } from "react";
import { useTransactionsData } from "variables/useTransactionsData";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

const FilterTransactions = () => {
  const { transactionsDataFiltered, getFilteredTransactionsData } =
    useTransactionsData();
  const [numDocument, setNumDocument] = useState("");
  const [countTransaction, setCountTransaction] = useState("");
  const [error, setError] = useState("");

  //colors
  const textInput = useColorModeValue("color.3", "white");
  const borderColor = useColorModeValue("color.5", "color.3");
  const colorButton = useColorModeValue("color.6", "color.6")
  const bg = useColorModeValue("white", "color.3");
  
  const handleNumDocumentChange = (event) => {
    setNumDocument(event.target.value);
  };

  const handleCountTransactionChange = (event) => {
    setCountTransaction(event.target.value);
  };

  const handleFilterSubmit = async (event) => {
    event.preventDefault();
    try {
      if (numDocument && countTransaction) {
        const filterParams = {
          amount: countTransaction,
          numDocument: numDocument,
        };
        await getFilteredTransactionsData(filterParams);
        setError("");
      } else {
        setError("Todos los campos son obligatorios");
      }
    } catch (error) {
      console.error(
        "Error al obtener los datos filtrados de transacciones:",
        error
      );
      setError("Error al obtener los datos filtrados de transacciones");
    }
  };

  return (
    <Box p={4} borderRadius="md"  shadow="md" border="1px" borderColor={borderColor}>
      <form onSubmit={handleFilterSubmit}>
        <Flex
          direction={{ base: "column", lg: "row" }} // En pantallas pequeñas, los elementos se apilan uno sobre otro, en pantallas grandes se muestran en fila
          align="flex-start"
          justify="center"
          spacing={4}
          marginBottom="20px"
        >
          <Box
            
            flex={{ base: "none", lg: 1 }}
            width={{ base: "100%", lg: "auto" }}
          >
            {" "}
            {/* En pantallas pequeñas, este elemento ocupa el ancho completo, en pantallas grandes se ajusta automáticamente */}
            <FormControl id="userId" isRequired>
              <FormLabel>Numero de Documento</FormLabel>
              <Input
                color={textInput}
                width="300px"
                type="text"
                value={numDocument}
                _placeholder={{color: textInput}}
                onChange={handleNumDocumentChange}
                placeholder="Ingrese el Numero Documento"
              />
            </FormControl>
          </Box>
          <Box
            flex={{ base: "none", lg: 1 }}
            width={{ base: "100%", lg: "auto" }}
          >
            {" "}
            {/* En pantallas pequeñas, este elemento ocupa el ancho completo, en pantallas grandes se ajusta automáticamente */}
            <FormControl id="countTransaction" isRequired>
              <FormLabel>Cantidad de transacciones</FormLabel>
              <Input
                color={textInput}
                width="300px"
                type="text"
                value={countTransaction}
                _placeholder={{color: textInput}}
                onChange={handleCountTransactionChange}
                placeholder="Ingrese la cantidad de transacciones"
              />
            </FormControl>
          </Box>
        </Flex>

        <Button type="submit"   variant="brand" bg={colorButton} width="200px">
          Filtrar
        </Button>
        {error && <Text color="red">{error}</Text>}
      </form>
      <Stack mt={4} spacing={2} bg={bg} borderRadius="md">
        {transactionsDataFiltered.map((transaction) => (
          <Box key={transaction.id}  p={4}>
            <Text fontSize="xl" fontWeight="bold">
              Detalles de la Transaction:
            </Text>
            <Text fontWeight="bold">Transaction ID: {transaction.id}</Text>
            <Text>
              <b>Creado en:</b>{" "}
              {new Date(transaction.created_at).toLocaleString()}
            </Text>
            <Text>
              <b>Actualizado en:</b>{" "}
              {new Date(transaction.updated_at).toLocaleString()}
            </Text>
            <Text> <b>Descripción:</b>{" "} {transaction.description}</Text>
            <Text> <b>Puntos:</b>{" "}{transaction.points}</Text>

            <Text fontSize="xl" fontWeight="bold">
              Datos del usuario Emisor:
            </Text>
            <Text>
              <b>ID:</b> {transaction.user.id}
            </Text>
            <Text>
              <b>Nombre:</b> {transaction.user.full_name}
            </Text>
            <Text>
              <b>Número de documento:</b> {transaction.user.num_document}
            </Text>
            <Text>
              <b>Email:</b> {transaction.user.email}
            </Text>
            <Text>
              <b>Teléfono:</b> {transaction.user.phone}
            </Text>
            <Text>
              <b>Puntos del usuario:</b> {transaction.user.points}
            </Text>

            <Text fontSize="xl" fontWeight="bold">
              {" "}
              Datos del Usuario Receptor{" "}
            </Text>
            <Text>
              <b>ID:</b> {transaction.recipient_user.id}
            </Text>
            <Text>
              <b>Nombre:</b> {transaction.recipient_user.full_name}
            </Text>
            <Text>
              <b>Número de documento:</b>{" "}
              {transaction.recipient_user.num_document}
            </Text>
            <Text>
              <b>Email:</b> {transaction.recipient_user.email}
            </Text>
            <Text>
              <b>Teléfono:</b> {transaction.recipient_user.phone}
            </Text>
            <Text>
              <b>Puntos:</b> {transaction.recipient_user.points}
            </Text>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default FilterTransactions;
