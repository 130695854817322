import React, { useState, useEffect, useMemo } from "react";
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  IconButton,
  useColorModeValue,
  Box,
  Select,
  Input,
  SimpleGrid,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Button,
  Image,
  Icon,
  Editable,
  EditableInput,
  EditablePreview,
  EditableTextarea,
} from "@chakra-ui/react";
import {
  MdCancel,
  MdCheckCircle,
  MdChevronLeft,
  MdChevronRight,
  MdEdit,
} from "react-icons/md";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import GetCategory from "api/category";
import { useAuth } from "auth-context/auth.context"; // Importa el contexto de autenticación

export default function TableProducts(props) {
  const { columnsData, tableData, updateProductData } = props;
  const { user } = useAuth(); // Obtiene el usuario autenticado del contexto de autenticación
  const [categories, setCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [offerFilter, setOfferFilter] = useState("");
  const [codeFilter, setCodeFilter] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedStock, setEditedStock] = useState(selectedProduct?.stock);
  const [editedDescription, setEditedDescription] = useState(
    selectedProduct?.description
  );
  const [letterCount, setLetterCount] = useState(0);

  //colors

  const colorModal = useColorModeValue("white", "color.3");
  const textColor = useColorModeValue("color.8", "color.8");
  const textInput = useColorModeValue("color.3", "white");
  const textColortable = useColorModeValue("color.3", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const bg = useColorModeValue("white", "color.3");
  const colorButton = useColorModeValue("color.6", "color.6");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await GetCategory.getCategory(user.token);
        setCategories(response.data);
      } catch (error) {
        console.error("Error al obtener las categorías:", error);
      }
    };

    if (user && user.token) {
      fetchCategories();
    }
  }, [user]); // Ejecuta el efecto cuando el usuario cambia

  const handleCategoryFilterChange = (e) => {
    const selectedCategory = e.target.value;
    setCategoryFilter(selectedCategory);
  };

  const handleNameFilterChange = (e) => {
    setNameFilter(e.target.value);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleOfferFilterChange = (e) => {
    setOfferFilter(e.target.value);
  };

  const handleCodeFilterChange = (e) => {
    setCodeFilter(e.target.value);
  };

  const handleEdit = (product) => {
    const editedProduct = JSON.parse(JSON.stringify(product));
    setSelectedProduct(editedProduct);
    setIsEditing(true);
  };

  const handleCloseModal = () => {
    setIsEditing(false);
  };

  const filteredData = useMemo(() => {
    let filtered = tableData;

    if (categoryFilter) {
      filtered = filtered.filter(
        (item) => item.category.category_name === categoryFilter
      );
    }
    if (nameFilter) {
      filtered = filtered.filter((item) =>
        item.name.toLowerCase().includes(nameFilter.toLowerCase())
      );
    }
    if (statusFilter) {
      filtered = filtered.filter((item) => item.active === statusFilter);
    }
    if (offerFilter === "true") {
      filtered = filtered.filter((item) => item.oferta === "Si");
    } else if (offerFilter === "false") {
      filtered = filtered.filter((item) => item.oferta === "No");
    }
    if (codeFilter) {
      filtered = filtered.filter((item) =>
        item.code.toLowerCase().includes(codeFilter.toLowerCase())
      );
    }
    return filtered;
  }, [
    tableData,
    categoryFilter,
    nameFilter,
    statusFilter,
    offerFilter,
    codeFilter,
  ]);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => filteredData, [filteredData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10, sortBy: [{ id: "id", desc: true }] },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state: { pageIndex },
  } = tableInstance;

  const handleStockChange = (e) => {
    setEditedStock(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    let text = e.target.value;
    if (text.length > 200) {
      text = text.slice(0, 200);
    }
    setEditedDescription(text);
    const count = text.length;
    setLetterCount(count);
  };

  const handleUpdateProduct = () => {
    const newData = {
      name: selectedProduct.name,
      stock: editedStock !== undefined ? editedStock : selectedProduct.stock,
      description:
        editedDescription !== undefined
          ? editedDescription
          : selectedProduct.description,
      active: selectedProduct.active === "activo" ? "1" : "0",
      imagen_product:
        selectedProduct.imagen_product !== undefined
          ? selectedProduct.imagen_product
          : null, // Cambiamos undefined por null
    };

    updateProductData(selectedProduct.id, newData);
    setIsEditing(false);
  };

  const clearFilters = () => {
    setCategoryFilter("");
    setNameFilter("");
    setStatusFilter("");
    setOfferFilter("");
    setCodeFilter("");
  };

  return (
    <>
      <Flex
        align="center"
        direction="column"
        w="100%"
        h="100%"
        overflowX="auto"
      >
        <Flex px="25px" justify="space-around" mb="20px" align="center">
          <SimpleGrid
            columns={{ base: 2, md: 6, lg: 6, "2xl": 6 }}
            gap="20px"
            mb="20px"
            alignItems="center"
          >
            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Filtrar Por :
            </Text>
            <Select
              width="auto"
              value={categoryFilter}
              onChange={handleCategoryFilterChange}
            >
              <option value="">Todas las categorías</option>
              {categories.map((category) => (
                <option key={category.id} value={category.category_name}>
                  {category.category_name}
                </option>
              ))}
            </Select>

            <Input
              color={textInput}
              width="auto"
              type="text"
              value={nameFilter}
              _placeholder={{ color: textInput, fontSize: 11 }}
              onChange={handleNameFilterChange}
              placeholder="Buscar por nombre..."
            />

            <Select
              width="auto"
              value={statusFilter}
              onChange={handleStatusFilterChange}
            >
              <option value="">Todos los estados</option>
              <option value="activo">Activo</option>
              <option value="inactivo">Inactivo</option>
            </Select>

            <Select
              width="auto"
              value={offerFilter}
              onChange={handleOfferFilterChange}
            >
              <option value="">Todas las ofertas</option>
              <option value="true">Oferta</option>
              <option value="false">Sin oferta</option>
            </Select>

            <Input
              color={textInput}
              width="auto"
              type="text"
              value={codeFilter}
              _placeholder={{ color: textInput, fontSize: 11 }}
              onChange={handleCodeFilterChange}
              placeholder="Buscar por código..."
            />
          </SimpleGrid>
        </Flex>
        <Button
        variant="brand"
          marginTop="-30px"
          marginBottom="10px"
          width="200px"
          bg={colorButton}
          onClick={clearFilters}
        >
          Limpiar filtros
        </Button>
      </Flex>
      <Box
        p="4"
        bg={bg}
        borderRadius="10px"
        overflowY="hidden"
        overflowX="auto"
        color={textColor}
      >
        <Table color={textColortable} variant="striped" {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                    >
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        maxWidth="200px"
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="initial"
                        title={cell.value}
                      >
                        {cell.column.Header === "Estado" ? (
                          <Flex align="center">
                            <Icon
                              w="24px"
                              h="24px"
                              me="5px"
                              color={
                                cell.value === "activo"
                                  ? "green.500"
                                  : cell.value === "inactivo"
                                  ? "red.500"
                                  : null
                              }
                              as={
                                cell.value === "activo"
                                  ? MdCheckCircle
                                  : cell.value === "inactivo"
                                  ? MdCancel
                                  : null
                              }
                            />
                            <Text>{cell.value}</Text>
                          </Flex>
                        ) : cell.column.Header === "imagen" &&
                          cell.value !== null ? (
                          <Box w="40px" h="40px">
                            <Image
                              src={`https:/${cell.value}`}
                              alt="Imagen del producto"
                            />
                          </Box>
                        ) : cell.column.Header === "Editar" ? (
                          <Tooltip label="Editar">
                            <IconButton
                              width="20px"
                              height="20px"
                              aria-label="Editar"
                              icon={<MdEdit />}
                              onClick={() => handleEdit(cell.row.original)}
                            />
                          </Tooltip>
                        ) : (
                          cell.render("Cell")
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
      <Flex justify="center" align="center" px="25px" mt="20px" fontSize="14px">
        <Text>
          Mostrando {pageIndex * page.length + 1} -{" "}
          {pageIndex * page.length + page.length} de {data.length} productos
        </Text>
        <Flex align="center">
          <IconButton
            variant="ghost"
            aria-label="Página anterior"
            icon={<MdChevronLeft />}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          />
          <Text mx="15px">
            Página{" "}
            <strong>
              {pageIndex + 1} de {pageOptions.length}
            </strong>
          </Text>
          <IconButton
            variant="ghost"
            aria-label="Página siguiente"
            icon={<MdChevronRight />}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          />
        </Flex>
      </Flex>

      <Modal isOpen={isEditing} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent bg={colorModal}>
          <ModalHeader>{`Editar Producto: ( ID: ${selectedProduct?.id})`}</ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel fontWeight="bold">Producto</FormLabel>
              <Text border="1px" borderColor={borderColor}>
                {" "}
                {selectedProduct?.name}
              </Text>
            </FormControl>
            <FormControl>
              <FormLabel fontWeight="bold">Stock</FormLabel>
              <Editable defaultValue={selectedProduct?.stock}>
                <EditablePreview
                  width="100%"
                  color={textColortable}
                  border="1px"
                  borderColor={borderColor}
                />
                <EditableInput
                  color={textColortable}
                  value={editedStock}
                  type="number"
                  placeholder="Stock"
                  required
                  onChange={handleStockChange}
                />
              </Editable>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel fontWeight="bold">
                Descripción : {letterCount} / 200
              </FormLabel>
              <Editable defaultValue={selectedProduct?.description}>
                <EditablePreview
                  border="1px"
                  color={textColortable}
                  borderColor={borderColor}
                  width="100%"
                  height="100px"
                />
                <EditableTextarea
                  maxLength={200}
                  height="80px"
                  color={textColortable}
                  type="text"
                  placeholder="Descripción"
                  value={editedDescription}
                  onChange={handleDescriptionChange}
                />
              </Editable>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel fontWeight="bold">Estado</FormLabel>
              <Select
                color={textColortable}
                value={
                  selectedProduct && selectedProduct?.active === "activo"
                    ? "activo"
                    : "inactivo"
                }
                onChange={(e) =>
                  setSelectedProduct((prevState) => ({
                    ...prevState,
                    active: e.target.value,
                  }))
                }
              >
                <option value="activo">Activo</option>
                <option value="inactivo">Inactivo</option>
              </Select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel fontWeight="bold">Imagen</FormLabel>
              <Flex justify="space-between" align="center">
                <Image
                  src={`https:/${selectedProduct?.imagen_product}`}
                  alt="Imagen del producto"
                  boxSize="100px"
                  objectFit="contain"
                />
                <Input
                  border="none"
                  p="4"
                  color={textColortable}
                  name="imagen_product"
                  type="file"
                  onChange={(e) =>
                    setSelectedProduct((prevState) => ({
                      ...prevState,
                      imagen_product: e.target.files[0],
                    }))
                  }
                />
              </Flex>
            </FormControl>
            <Box display="flex" justifyContent="center">
              <Button
                bg={colorButton}
                mt={4}
                colorScheme="teal"
                onClick={handleUpdateProduct}
              >
                Actualizar
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
