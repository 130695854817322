import axios  from 'api'

const transactions ={
    getTransactions:(token) =>{
        return axios.get(`${base}/transactions`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    },
    getTransactionsParams: (token, params) => {
        const { numDocument, amount } = params;
        const url = `${base}/get_filter?count_transaction=${amount}&num_document=${numDocument}`;
        
        return axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      },  
}
let  base ="transaction"

export default transactions;