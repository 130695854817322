import {
  Box,
    Flex,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue,
  } from "@chakra-ui/react";
  import React, { useState, useEffect } from "react";
  
  const TablaCouponsExchanges = (props) => {
    
    const [data, setData] = useState([]);
    const [sortConfig, setSortConfig] = useState({
      key: null,
      direction: "desc",
    });
    const { columnsDataChange, tableDataChange} = props; // Agregar onFilterChange como prop
;  //colors
const TextColumn = useColorModeValue("color.6", "color.6");
const textColor = useColorModeValue("color.3", "white");
const bg = useColorModeValue("white", "color.3");
   

    useEffect(() => {
      // Ordenar los datos de forma descendente según el ID
      const sortedData = [...tableDataChange].sort((a, b) => b.id - a.id);
      setData(sortedData);
    }, [tableDataChange]);
  
    const requestSort = (key) => {
      let direction = "asc";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "asc"
      ) {
        direction = "desc";
      }
      setSortConfig({ key, direction });
    };
  
    const sortedData = () => {
      const sortableData = [...data];
      if (sortConfig.key) {
        sortableData.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "asc" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "asc" ? 1 : -1;
          }
          return 0;
        });
      }
      return sortableData;
    };
  
    return (
      <Flex direction="column" w="100%" h="100%" overflowX="auto">
      
  
        <Box p="4"  bg={bg} borderRadius="md">
        <Table variant="striped" color={textColor}>
          <Thead>
            <Tr>
              {columnsDataChange.map((column, index) => (
                <Th
                  key={index}
                  bg={bg}
                  color={TextColumn}
                  onClick={() => requestSort(column.accessor)}
                >
                  {column.Header}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {sortedData().map((row, rowIndex) => (
              <Tr bg={bg} key={rowIndex}>
                {columnsDataChange.map((column, cellIndex) => (
                  <Td bg={bg} key={cellIndex}>
                    {row[column.accessor]} {/* Renderizar el dato directamente */}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
        </Box>
      </Flex>
    );
  };
  
  export default TablaCouponsExchanges;
  