import axios from "api";

const exchangeProducts = {
  getTransaction: (token) => {
    return axios.get(`${base}/exchange_product`, {
      headers: {
        Authorization: `Bearer ${token} `,
      },
    });
  },
  updateState: (token, id, newStatusId) => {
    return axios.put(`${base}/update/${id}`, newStatusId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

let base = "exchanges_products";

export default exchangeProducts;
