import React, { useCallback, useEffect, useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import NavbarLinksUser from "components/navbar/NavbarUsers";
import CreateUserModal from "./RegistroUser";
import { useUsersData } from "variables/useUsersData";
import AllUsers from "./components/AllUsers";

export default function Users() {
  // Chakra Color Mode
  //const textColor = useColorModeValue("secondaryGray.900", "white");
  //const textColorBrand = useColorModeValue("brand.500", "white");
 //let menuBg = useColorModeValue("white", "navy.800");
 const shadow = useColorModeValue(
  "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
  "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
);
const colorButton = useColorModeValue("color.6", "color.6")

  const textColor = useColorModeValue("gray.700", "white");
  const [searchTerm, setSearchTerm] = useState("");
  const { usersData, getUsersData, createUser } = useUsersData();
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
 
  

  useEffect(() => {
    setFilteredUserData(usersData);
  }, [usersData, searchTerm]);

  // Función para manejar el cambio en el campo de entrada de búsqueda
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setShowAlert(false); // Ocultar la alerta al escribir
  };

  // Definir handleSearch antes del useEffect
  const handleSearch = useCallback(() => {
    if (searchTerm === "") {
      setFilteredUserData(usersData);
      return;
    }
  
    const filteredUsers = usersData.filter((user) => {
      const { id, full_name, num_document } = user;
      const searchLowerCase = searchTerm.toLowerCase();
      const idAsString = String(id);
      const idMatches = idAsString.includes(searchLowerCase);
      const fullNameMatches =
        typeof full_name === "string" &&
        full_name.toLowerCase().includes(searchLowerCase);
      const numDocumentMatches =
        typeof num_document === "string" &&
        num_document.toLowerCase().includes(searchLowerCase);
      return idMatches || fullNameMatches || numDocumentMatches;
    });
    setFilteredUserData(filteredUsers);
    // Mostrar la alerta después de 2 segundos si no se encuentran resultados
    if (filteredUsers.length === 0) {
      setTimeout(() => {
        setShowAlert(true);
      }, 1000);
    } else {
      setShowAlert(false);
    }
  }, [searchTerm, usersData]);
  

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      handleSearch();
    }, 300); // 300 milisegundos de espera antes de ejecutar la búsqueda
  
    return () => clearTimeout(delaySearch); // Limpiar el temporizador en cada cambio del término de búsqueda
  }, [searchTerm, handleSearch]);
  



  const columns = [
    { Header: "ID", accessor: "id" },
    { Header: "Nombre Completo", accessor: "full_name" },
    { Header: "Documento", accessor: "num_document" },
    { Header: "Telefono", accessor: "phone" },
    { Header: "País", accessor: "country" },
    { Header: "Correo Electrónico", accessor: "email" },
    { Header: "Puntos", accessor: "points" },
    { Header: "Rol", accessor: "user_admin" },
    { Header: "Estado", accessor: "user_active" },
    { Header: "Creado", accessor: "created_at" },
    
    
  ];
 

  const formattedUserData = filteredUserData.map((user) => ({
    id: user.id,
    full_name: user.full_name,
    num_document: user.num_document,
    phone: user.phone,
    country: user.country.country,
    email: user.email,
    points: user.points,
    user_admin: user.user_admin ? "Admin" : "User",
    user_active: user.user_active ? "activo" : "inactivo",
    created_at:  new Date(user.created_at).toLocaleDateString(
      "es-ES",
      { day: "numeric", month: "short", year: "numeric" }
    )
  }));

 

  const handleModalClose = () => {
    setIsModalOpen(false);
    getUsersData(); 
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  return (
    <Flex maxWidth="100%" direction="column">
      <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          gap="20px"
          mb="20px"
          alignContent="space-between"
          columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
        >
          <Flex direction="column">
            <Text color={textColor} fontSize="small" fontWeight="600">
              Buscar por ID, Nombre o Documento :
            </Text>
            <NavbarLinksUser
              searchTerm={searchTerm}
              onChange={handleSearchChange}
              onSearch={handleSearch}
            />
          </Flex>

          <Flex
        
            direction="column"
            alignItems="center"
            p="10px"
            borderRadius="10px"
            boxShadow={shadow}
          >
            <Text
              color={textColor}
              fontSize="small"
              fontWeight="600"
              alignItems="flex-end"
            >
           Nuevo Usuario
            </Text>
            <Button variant="brand"  bg={colorButton} width="100%" fontSize="xl" onClick={openModal}>
              {" "}
              +{" "}
            </Button>
            <CreateUserModal isOpen={isModalOpen} onClose={handleModalClose} createUser={createUser} />
          </Flex>
        </SimpleGrid>

        <Flex
          flexDirection="column"
          
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
        >
          
            {showAlert && (
              <Text marginLeft="20px" color="red.500">
                No se encontraron usuarios que coincidan con la búsqueda.
              </Text>
            )}
            <AllUsers
              tableData={formattedUserData}
              columnsData={columns}
            />
         
        </Flex>
      </Box>
    </Flex>
  );
}
