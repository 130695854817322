import axios from "../api/index";

const Getusers = {
  getUsers: (token) => {
    return axios.get(`${base}/getusers`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  registerUser: (userData) => {
    return axios.post(`${base}/register`, userData);
  },
  updateState: (token, id) => {
    return axios.post(`${base}/toggle-user/${id}`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getTotalUsersPoints: (token) => {
    return axios.get(`${base}/addlesspoints`, {
      headers: {
        Authorization: `Bearer ${token} `,
      },
    });
  },
  updatePointsUser: (token, id, data) => {
    return axios.post(`${base}/addlesspoints/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  changePassword: (token, data) => {
    return axios.post(`${base}/change-password`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  recoveryPassword: (data) => {
    return axios.post(`${base}/recovery-password-1`, data);
  },

  getrecoveryPassword: (token) => {
    return axios.get(`${base}/recovery-password-2/${token}`);
  },

  newPassword: (data) => {
    return axios.post(`${base}/recovery-password-3`, data);
  },
};

let base = "users";

export default Getusers;
