import { Box, Button, Icon, Input, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
import CardPoints from "components/card/cardPoints/CardPoints";
import { useEffect, useState } from "react";
import { MdAddTask, MdAttachMoney } from "react-icons/md";
import usePoints from "variables/usePoints";
import { useUsersData } from "variables/useUsersData";

export default function PointsCompany() {
 const {usersTotalPoints} = useUsersData();
  const { pointsCompany, updatePointsCompany } = usePoints();
  const [pointsToAddCompany, setPointsToAddCompany] = useState("");
  const [currentPointsCompany, setCurrentPointsCompany] = useState(pointsCompany);
  
 
   //colors
   const textInput = useColorModeValue("color.3", "white");
   const textColor= useColorModeValue("color.3", "white");
   const borderColorBox = useColorModeValue("color.6", "color.6");
   const colorButton = useColorModeValue("color.6", "color.6")

  

  useEffect(() => {
    setCurrentPointsCompany(pointsCompany);
  }, [pointsCompany, usersTotalPoints]);

  const handleAddPointsCompany = async () => {
    const valueToAdd = parseInt(pointsToAddCompany);
    if (!isNaN(valueToAdd)) {
      const confirmAddPoints = window.confirm(
        "¿Estás seguro de que deseas agregar puntos al usuario?"
      );
      if (confirmAddPoints) {
        await updatePointsCompany({ sumar: valueToAdd });
        setCurrentPointsCompany((prevPoints) => prevPoints + valueToAdd);
        setPointsToAddCompany("");
      }
    } else {
      console.error("El valor ingresado no es un número válido");
    }
  };

  const handleRemovePointsCompany = async () => {
    const valueToRemove = parseInt(pointsToAddCompany);
    if (!isNaN(valueToRemove)) {
      const confirmRemovePoints = window.confirm(
        "¿Estás seguro de que deseas quitar puntos al usuario?"
      );
      if (confirmRemovePoints) {
        await updatePointsCompany({ restar: valueToRemove });
        setCurrentPointsCompany((prevPoints) => prevPoints - valueToRemove);
        setPointsToAddCompany("");
      }
    } else {
      console.error("El valor ingresado no es un número válido");
    }
  };

  return (
    <>
      <Box  border="1px" p="4" borderRadius="md"  borderColor={borderColorBox}>
      <SimpleGrid
        columns={{ base: 3, md: 3, lg: 3, "2xl": 3 }}
        gap="20px"
        mb="20px"
      >
        <CardPoints
          name="Total de Puntos"
          value={usersTotalPoints + currentPointsCompany}
        />
        <CardPoints name="Puntos Repartidos" value={usersTotalPoints} />
        <CardPoints name="Puntos Actuales" value={currentPointsCompany} />
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 3, md: 3, lg: 3, "2xl": 3 }}
        gap="20px"
        mb="20px"
      >
        {/* Botones para agregar y quitar puntos */}
        <Button
         variant="brand"
          onClick={handleAddPointsCompany}
          color="white"
          bg={colorButton}
          leftIcon={<Icon as={MdAddTask} />}
        >
          Agregar Puntos
        </Button>
        <Input
        color={textInput}
          value={pointsToAddCompany}
          onChange={(e) => setPointsToAddCompany(e.target.value)}
          placeholder="Ingrese la cantidad de puntos"
          _placeholder={{ color: textInput}}
        />
        <Button
         variant="brand"
        color={textColor}
          onClick={handleRemovePointsCompany}
         bg={colorButton}
          leftIcon={<Icon as={MdAttachMoney} />}
        >
          Quitar Puntos
        </Button>
      </SimpleGrid>
      </Box>
    </>
  );
}
