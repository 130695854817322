import { useAuth } from "auth-context/auth.context";
import { useEffect, useState, useCallback } from "react";
import GetProducts from "api/products";
import { useToast } from "@chakra-ui/react";

export default function useProductsData() {
    const { user } = useAuth();
    const toast = useToast();
    const [productsData, setProductsData] = useState([]);

    // Envolver la definición de fetchData dentro de useCallback
    const fetchData = useCallback(async () => {
        try {
            if (user && user.token) {
                const response = await GetProducts.getProducts(user.token);
                setProductsData(response.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }, [user]); // Asegúrate de incluir todas las dependencias necesarias aquí

    useEffect(() => {
        // Llamar a fetchData dentro de useEffect
        fetchData();
    }, [fetchData]); 
 
    const createProduct = async (formData) =>{
        try {
            await GetProducts.postProducts(user.token, formData);
            const response = await GetProducts.getProducts(user.token);
            setProductsData(response.data);
            toast({
                title: 'Producto agregado',
                description: `Se ha agregado el Producto ${formData.name}`,
                status: 'success',
                duration: 5000,
              });
        } catch (error) {
            console.error("Error agregar el producto:", error);
            toast({
                title: "Error al guardar el producto.",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
           
        }
    }

    const updateProductData = async (id, newData) => {
        try {
            if (user && user.token) {
                await GetProducts.putProducts(user.token, id, newData);
                fetchData();
            }
        } catch (error) {
            console.error("Error updating product data:", error);
        }
    };

    return {
        productsData,
        createProduct,
        updateProductData
    };
}
