import {  extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

// default base style from the Table theme
const baseStyle = {
  table: {
    fontVariantNumeric: "lining-nums tabular-nums",
    borderCollapse: "collapse",
    width: "full",
  },
  th: {
    fontFamily: "heading",
    fontWeight: "bold",
    textTransform: "uppercase",
    letterSpacing: "wider",
    textAlign: "start",
  },
  td: {
    textAlign: "start",
  },
  caption: {
    mt: 4,
    fontFamily: "Roboto, sans-serif",
    textAlign: "center",
    fontWeight: "medium",
  },
};

const variantStriped = (props) => {
  const { colorScheme: c } = props;

  return {
    tr: {},
    th: {
      textAlign: "center",
      color: "color.8",
      background: mode("white", "color.3")(props),
      fontFamily: "Roboto, sans-serif",
      fontWeight: "bold"
    },
    td: {
      textAlign: "center",
      fontFamily: "Roboto, sans-serif",
      fontStyle: "normal",
      fontWeight: 100,
      color: mode("#000000","#FFFFFF") (props)
    },
    caption: {
      color: mode(`${c}.600`, `${c}.100`)(props),
    },
    tbody: {
      tr: {
        "&:nth-of-type(odd)": {
          td: {
            background: mode("#BAE4FF", "color.1")(props),
          },
        },
        "&:nth-of-type(even)": {
          td: {
            background: mode("white", "color.3")(props),
          },
        },
        _hover:{
          td: {
            bg: mode("#88CEFA", "#88CEFA")(props), // Cambia el color de fondo en hover según el modo de color
          },
        }
      },
    },
    tfoot: {
      tr: {
        "&:last-of-type": {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  };
};

const variantSimple = (props) => ({
  td: {
    fontWeight: "semibold", // change font weight to semibold
  },
});

const variants = {
  simple: variantSimple,
  striped: variantStriped,
};

const xl = {
  fontSize: "lg",
  px: "4",
  h: "12",
};

const sizes = {
  xl: {
    td: xl,
    th: xl,
  },
};

const tableTheme = {
  baseStyle,
  variants,
  sizes,
};

const Tabletheme = extendTheme({
  components: {
    Table: tableTheme,
  },
});

export default Tabletheme;
