import { Box, Flex,  Text, useColorModeValue, Select } from "@chakra-ui/react";
import Card from "components/card/Card";
import BarChart from "components/charts/BarChart";
import useTransactionChartData from "variables/useTransactionChartData";
import { useState } from "react";

export default function DailyTraffic(props) {
  const [view, setView] = useState('daily'); // Estado para manejar la vista seleccionada
  const {
    barChartDataDailyTraffic,
    barChartOptionsDailyTraffic,
    totalTransactions,
    percentageChange,
    changeIcon,
    changeColor

  } = useTransactionChartData(view);

  // Colors
  const textColor = useColorModeValue("color.3", "white");
  const { ...rest } = props;

  return (
    <Card align='center' direction='column' w="100%" {...rest}>
      <Flex justify='space-between' align='start' px='10px' pt='5px'>
        <Flex flexDirection='column' align='start' me='20px'>
          <Flex w="100%">
            <Text
              me="auto"
              color={textColor}
              fontSize="sm"
              fontWeight="500"
            >
              {view === 'daily' ? 'Transacciones Diarias' : 'Transacciones Semanales'}
            </Text>
          </Flex>
          <Flex align="end">
            <Text
              color={textColor}
              fontSize="34px"
              fontWeight="700"
              lineHeight="100%"
            >
              {totalTransactions}
            </Text>
            <Text
              ms="6px"
              color={textColor}
              fontWeight="500"
              fontSize="sm"
            >
              Transacción{view === 'daily' ? '' : 'es'}
            </Text>
          </Flex>
        </Flex>
        <Flex align="center">
          {changeIcon}
          <Text color={changeColor} fontSize="10px" fontWeight="700">
           {percentageChange} %
          </Text>
        </Flex>
        <Select
          value={view}
          onChange={(e) => setView(e.target.value)}
          size="sm"
          variant="outline"
          maxW="150px"
        >
          <option value="daily">Diario</option>
          <option value="weekly">Semanal</option>
        </Select>
      </Flex>

      <Box h='240px' mt='auto'>
        <BarChart
          chartData={barChartDataDailyTraffic}
          chartOptions={barChartOptionsDailyTraffic}
        />
      </Box>
    </Card>
  );
}
