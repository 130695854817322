import { useAuth } from "auth-context/auth.context";
import { useState, useEffect } from "react";
import transactions from "api/transactions";

export function useTransactionsData() {
  const { user } = useAuth();
  const [transactionsData, setTransactionsData] = useState([]);
  const [transactionsDataFiltered, setTransactionsDataFiltered] = useState([]);
  const [transactionsCountByDay, setTransactionsCountByDay] = useState(0);
  const [transactionsDay, setTransactionsDay] = useState({});
  

  useEffect(() => {
    const fetchAllTransactionsData = async () => {
      try {
        const response = await transactions.getTransactions(user.token);
        const data = response.data;
        setTransactionsData(data);
      } catch (error) {
        console.error("Error al obtener todos los datos de transacciones:", error);
      }
    };
  
   
      fetchAllTransactionsData();
    
  }, [user.token]);

useEffect(() =>{
  setTransactionsCountByDay(transactionsData.length);
},[transactionsData])


  // Función para obtener los datos filtrados de transacciones
  const getFilteredTransactionsData = async (filterParams) => {
    try {
      const { numDocument, amount } = filterParams;
      const response = await transactions.getTransactionsParams(user.token, { numDocument, amount });
      setTransactionsDataFiltered(response.data);
    } catch (error) {
      console.error(
        "Error al obtener los datos filtrados de transacciones:",
        error
      );
    }
  };
  
  useEffect(() =>{
    const calculateTransactions=  async () => {
      try {
        const transactionsByDay = transactionsData.reduce((acc, transaction) => {
          const date = new Date(transaction.created_at).toLocaleDateString("es-ES", {
            day: "numeric",
            month: "short",
            year: "numeric",
          });
          if (!acc[date]) {
            acc[date] = 0;
          }
          acc[date]++;
          return acc;
        }, {});
    
        setTransactionsDay(transactionsByDay);
      } catch (error) {
        
      }
      
    };
    calculateTransactions();
  },[user, transactionsData])

 


  return {
    transactionsData,
    transactionsDataFiltered,
    transactionsCountByDay,
    getFilteredTransactionsData,
    transactionsDay
  };
}
