import { Box, Text,  Button, useColorModeValue } from "@chakra-ui/react";
import {  useState } from "react";
import useBugsReport from "variables/useBugsRepots";



const BugReport = () => {
  
 const {bugReportsData}= useBugsReport();
  const [currentPage, setCurrentPage] = useState(1);
  const reportsPerPage = 5;
  // Logic to paginate
  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = bugReportsData.slice(
    indexOfFirstReport,
    indexOfLastReport
  );


  //Colors
  const borderColorBox = useColorModeValue("color.6", "color.6");
  const bg = useColorModeValue("white", "color.3");
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {currentReports.map((report) => (
        <Box width="60%"
        bg={bg}
          key={report.id}
          borderColor={borderColorBox}
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          p="4"
          mb="4"
          boxShadow="md"
        >
          <Text fontSize="xl" fontWeight="bold" mb="2">
            Reporte de {report.problem_type} #{report.id}
          </Text>
          <Text>
            <Text as="span" fontWeight="bold">
              Usuario:
            </Text>{" "}
            {report.user.full_name}
          </Text>
          <Text>
            <Text as="span" fontWeight="bold">
              Tipo de Problema:
            </Text>{" "}
            {report.problem_type}
          </Text>
          <Text>
            <Text as="span" fontWeight="bold">
              Descripción:
            </Text>{" "}
            {report.description}
          </Text>
        </Box>
      ))}
      {/* Pagination */}
      <Box mt="4" textAlign="center">
        {bugReportsData.length > reportsPerPage && (
          <Box>
            {Array.from({
              length: Math.ceil(bugReportsData.length / reportsPerPage),
            }).map((item, index) => (
              <Button
                key={index}
                onClick={() => paginate(index + 1)}
                mr="2"
                colorScheme={currentPage === index + 1 ? "blue" : "gray"}
              >
                {index + 1}
              </Button>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BugReport;
