import axios from "./index";

const base = "users";

class AuthApi {
  static Login = (data) => {
    return axios.post(`${base}/login`, data)
  };

  static Verify = (data) => {
    return axios.post(`${base}/login_code`, data)
  };
}



export default AuthApi;
