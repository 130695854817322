import React, { useEffect, useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/Banner3.jpg";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import useAuth2fa from "variables/auth/useAuth2FA";
import { RiEyeCloseLine } from "react-icons/ri";
import Config2FA from "../2fa/Config2FA";
import Verify2FA from "../2fa/Verify2FA";
import { useAuth } from "auth-context/auth.context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useUsersData } from 'variables/useUsersData';


function SignIn() {
  const { user, setUser } = useAuth();
  const toast = useToast()
  const history = useHistory();
  const { login } = useAuth2fa();
  const [error, setError] = useState(undefined);
  const [numDocument, setNumDocument] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showTwoFactorVerification, setShowTwoFactorVerification] =
    useState(false);
  const [configData, setConfigData] = useState();
  const [mounted, setMounted] = useState(false); // Variable para rastrear si el componente está montado

  const [isRecoveryModalOpen, setRecoveryModalOpen] = useState(false);
  const [recoveryEmail, setRecoveryEmail] = useState("");
  const { recoveryPassword } = useUsersData();
  const handleClick = () => setShow(!show);
  const colorInput = useColorModeValue("color.3", "white");


  // Colors
  const textColor = useColorModeValue("color.2", "white");
  const bgButton = useColorModeValue("color.6", "color.6");
  const textColorButton = useColorModeValue("color.3", "white");

  useEffect(() => {
    setMounted(true); // El componente está montado
    return () => {
      setMounted(false); // El componente se desmonta
    };
  }, []);

  useEffect(() => {
    // Función para limpiar localStorage si la sesión ha expirado
    const handleSessionExpiration = () => {
      const expirationTime = localStorage.getItem("expirationTime");
      const currentTime = Date.now();
      if (expirationTime && currentTime > parseInt(expirationTime)) {
        localStorage.removeItem("user");
        localStorage.removeItem("expirationTime");
        sessionStorage.removeItem("loginTime");
      }
    };

    // Verifica la expiración de la sesión al cargar el componente
    handleSessionExpiration();

    // Agrega event listeners para detectar la interacción del usuario
    const handleInteraction = () => {
      // Actualiza el tiempo de expiración
      const expirationTime = Date.now() + 86400000; // 24 horas en milisegundos
      localStorage.setItem("expirationTime", expirationTime.toString());
    };
    document.addEventListener("mousemove", handleInteraction);
    document.addEventListener("keypress", handleInteraction);

    // Limpia los event listeners al desmontar el componente
    return () => {
      document.removeEventListener("mousemove", handleInteraction);
      document.removeEventListener("keypress", handleInteraction);
    };
  }, []);

  const handleLogin = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (user && user.token) {
      return history.push("/admin/dashboards");
    }
    if (numDocument === "") {
      return setError("You must enter your numDocument.");
    }
    if (password === "") {
      return setError("You must enter your password");
    }
    try {
      setIsLoading(true);
      const dataToSend = {
        num_document: numDocument,
        password,
      };

      const response = await login(dataToSend);
      if (!response || !response.data) {
        if (error.response.data.error === "Contraseña incorrecta") {
          return setError(error.response.data.error);
        }
        return setError("Número de documento o contraseña incorrectos.");
      } else {
        const { qrcode, num_document, is_authenticated, secret_key } =
          response.data;

        if (secret_key === null && !is_authenticated) {
          setConfigData({ qrcode, num_document, is_authenticated, secret_key });
          setShowTwoFactorVerification(true);
        } else if (secret_key !== null && !is_authenticated) {
          setConfigData({ qrcode, num_document, is_authenticated, secret_key });
          setShowTwoFactorVerification(true);
        } else if (is_authenticated) {
          // Manejar la autenticación directamente
          const user = response.data;
          const accessToken = response.data.access_token;
          const isAdmin = response.data.user_admin;
          if (isAdmin === true && mounted) {
            const userWithToken = {
              ...user,
              token: accessToken,
              data: response.data,
            };
            const userJSON = JSON.stringify(userWithToken);
            setUser(userWithToken);
            localStorage.setItem("user", userJSON);
            // Establece el tiempo de expiración para 1 día (en milisegundos)
            const expirationTime = Date.now() + 86400000; // 24 horas en milisegundos
            localStorage.setItem("expirationTime", expirationTime.toString());

            // Guarda el tiempo de inicio de sesión en sessionStorage
            sessionStorage.setItem("loginTime", Date.now().toString());
            return history.push("/dashboards");
          } else {
            showAlert("No tiene permitido el acceso");
          }
        }
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError("Número de documento o contraseña incorrectos.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const showAlert = (message) => {
    alert(message);
    setUser(null);
    localStorage.removeItem("user");
    return history.push("/auth/signin");
  };
  const handlePasswordRecovery = async () => {
    try {
      // Asegúrate de enviar un objeto con el email
      await recoveryPassword({ email: recoveryEmail });
  
      toast({
        title: "Correo enviado.",
        description: "Revisa tu bandeja de entrada para restablecer tu contraseña.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
  
      setRecoveryEmail("");
      history.push("/recovery-password-2"); 
    } catch (error) {
      toast({
        title: "Error.",
        description: error.message || "Hubo un problema al enviar el correo de recuperación.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  

  return (
    <>
      {showTwoFactorVerification ? (
        configData.secret_key === null && !configData.is_authenticated ? (
          <Config2FA
            qrcode={configData.qrcode}
            num_document={configData.num_document}
            is_authenticated={configData.is_authenticated}
            secret_key={configData.secret_key}
          />
        ) : (
          <Verify2FA num_document={configData.num_document} />
        )
      ) : (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
          <Flex
            maxW={{ base: "100%", md: "max-content" }}
            w="100%"
            mx={{ base: "auto", lg: "0px" }}
            me="auto"
            h="100%"
            alignItems="start"
            justifyContent="center"
            mb={{ base: "30px", md: "60px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "40px", md: "14vh" }}
            flexDirection="column"
          >
            <Box me="auto">
              <Heading color={textColor} fontSize="36px" mb="10px">
                Login
              </Heading>
              <Text
                mb="36px"
                ms="4px"
                color={textColor}
                fontWeight="400"
                fontSize="md"
              >
                Ingrese su documento y Contraseña
              </Text>
            </Box>
            <Flex
              zIndex="2"
              direction="column"
              w={{ base: "100%", md: "420px" }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
            >
              <form onSubmit={handleLogin}>
                <FormControl id="numDocument">
                  <FormLabel color={textColor}>Número de Documento</FormLabel>
                  <Input
                    color={textColor}
                    variant="auth"
                    type="text"
                    value={numDocument}
                    onChange={(e) => setNumDocument(e.target.value)}
                    required
                    id="numDocument"
                    aria-required="true"
                    autoComplete="username"
                  />
                </FormControl>

                <FormControl id="password" mt={4}>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontWeight="500"
                    color={textColor}
                  >
                    Contraseña
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      id="password"
                      type={show ? "text" : "password"}
                      variant="auth"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      autoComplete="current-password"
                    />
                    <InputRightElement
                      display="flex"
                      alignItems="center"
                      mt="4px"
                    >
                      <Icon
                        color={textColor}
                        _hover={{ cursor: "pointer" }}
                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleClick}
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                {error && <Box color="red">{error}</Box>}
                <Button
                  bg={bgButton}
                  variant="brand"
                  color={textColorButton}
                  isLoading={isLoading}
                  loadingText="Signing In"
                  type="submit"
                  mt={4}
                  w="100%"
                >
                  Login
                </Button>
              </form>

              <Button
                variant="link"
                color={textColor}
                onClick={() => setRecoveryModalOpen(true)}
                mt={4}
              >
                ¿Olvidaste tu contraseña?
              </Button>
            </Flex>
          </Flex>

           {/* Modal de recuperación de contraseña */}
           <Modal isOpen={isRecoveryModalOpen} onClose={() => setRecoveryModalOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Recuperar Contraseña</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FormControl id="recoveryEmail">
                  <FormLabel>Correo Electrónico</FormLabel>
                  <Input
                    type="email"
                    color={colorInput}
                    value={recoveryEmail}
                    onChange={(e) => setRecoveryEmail(e.target.value)}
                    required
                  />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" onClick={handlePasswordRecovery}>
                  Enviar
                </Button>
                <Button onClick={() => setRecoveryModalOpen(false)} ml={3}>
                  Cancelar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </DefaultAuth>
      )}
    </>
  );
}

export default SignIn;
