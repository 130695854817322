import React, { useEffect, useState } from "react";
import { Flex, Box, Text, Button, useToast, Img, useColorModeValue } from "@chakra-ui/react";
import QRCode from "react-qr-code";
import Verify2FA from "./Verify2FA";

const Config2FA = ({ qrcode, num_document }) => {
  const toast = useToast();
  const [showVerify2FA, setShowVerify2FA] = useState(false);
  const [secret, setSecret] = useState("");

  useEffect(() => {
    if (!qrcode) {
      toast({
        title: "Error",
        description: "Error al generar el código QR.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      const urlParams = new URLSearchParams(qrcode.split("?")[1]);
      setSecret(urlParams.get("secret"));
    }
  }, [qrcode, toast]);

  const colorText = useColorModeValue('color.1', 'white')

  const redirectToGoogleAuthenticatorAndroid = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      "_blank"
    );
  };

  const redirectToGoogleAuthenticatorIOS = () => {
    window.open(
      "https://apps.apple.com/us/app/google-authenticator/id388497605",
      "_blank"
    );
  };

  const redirectToFreeOTPAndroid = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=org.fedorahosted.freeotp",
      "_blank"
    );
  };

  const redirectToFreeOTPIOS = () => {
    window.open(
      "https://apps.apple.com/us/app/freeotp-authenticator/id872559395",
      "_blank"
    );
  };

  const handleVerifyClick = () => {
    setShowVerify2FA(true);
  };

  return (
    <>
      {showVerify2FA ? (
        <Verify2FA num_document={num_document} secret_key={secret} />
      ) : (
        <Flex align="center" justify="center" h="100vh">
          <Box
            width="750px"
            height="xxl"
            maxW="4xl"
            p="4"
            borderWidth="1px"
            borderRadius="lg"
            boxShadow="lg"
            textAlign="center"
            alignContent="center"
          >
            <Text fontSize="xl" mb="4">
              Configuración de Autenticación de Dos Factores (2FA)
            </Text>
            <Text mb="4">
              Escanea el código QR con una aplicación de autenticación como
              Google Authenticator, FreeOTP, o cualquier otra aplicación
              compatible con TOTP.
            </Text>
            {qrcode && (
              <Flex justifyContent="center">
               <Box bg="white" p="4" borderRadius="lg" mb="4"> {/* Contenedor blanco alrededor del código QR */}
                <QRCode
                  value={qrcode}
                  size={190}
                  style={{ marginBottom: "1rem", maxWidth: "100%" }}
                />
              </Box>
              </Flex>
            )}
            <Text fontSize="sm" mb="2">
              Descarga una aplicación de autenticación:
            </Text>
            <Flex justifyContent="space-around" mb="4" p="4">
              <Flex direction="column" mb="4">
                <Button
                  style={{ padding: 0, maxWidth: "150px", border: "none" }}
                  border="none"
                  bg="transparent"
                  _hover={{ bg: "none" }}
                  _active={{ bg: "none" }}
                  marginBottom="4"
                >
                  <Img
                    src="https://upload.wikimedia.org/wikipedia/commons/8/84/Google_Authenticator_%28April_2023%29.svg"
                    alt="Google Authenticator"
                    boxSize="40px"
                    marginRight="8px"
                  />
                  Google Authenticator
                </Button>
                <Button
                  colorScheme="teal"
                  onClick={redirectToGoogleAuthenticatorAndroid}
                  variant="outline"
                  style={{ padding: 0, maxWidth: "150px", border: "none" }}
                >
                  <img
                    src="https://freeotp.github.io/img/playstore.svg"
                    alt="Google Authenticator"
                    style={{ width: "150px", height: "auto" }}
                  />
                </Button>
                <Button
                  marginTop="6px"
                  onClick={redirectToGoogleAuthenticatorIOS}
                  variant="outline"
                  style={{ padding: 0, maxWidth: "150px", border: "none" }}
                >
                  <img
                    src="https://freeotp.github.io/img/appstore.svg"
                    alt="App Store"
                    style={{ width: "100%", height: "auto" }}
                  />
                </Button>
              </Flex>
              <Flex direction="column" mb="4">
                <Button
                  style={{ padding: 0, maxWidth: "150px", border: "none" }}
                  border="none"
                  bg="transparent"
                  _hover={{ bg: "none" }}
                  _active={{ bg: "none" }}
                  marginBottom="4"
                >
                  <Img
                    src="https://upload.wikimedia.org/wikipedia/commons/d/d1/FreeOTP.svg"
                    alt="FreeOTP"
                    boxSize="40px"
                    marginRight="8px"
                  />
                  FreeOTP
                </Button>
                <Button
                  colorScheme="teal"
                  onClick={redirectToFreeOTPAndroid}
                  style={{ padding: 0, maxWidth: "150px", border: "none" }}
                  variant="outline"
                >
                  <img
                    src="https://freeotp.github.io/img/playstore.svg"
                    alt="FreeOTP"
                    style={{ width: "150px", height: "auto" }}
                  />
                </Button>
                <Button
                  marginTop="6px"
                  onClick={redirectToFreeOTPIOS}
                  variant="outline"
                  style={{ padding: 0, maxWidth: "150px", border: "none" }}
                >
                  <img
                    src="https://freeotp.github.io/img/appstore.svg"
                    alt="App Store"
                    style={{ width: "100%", height: "auto" }}
                  />
                </Button>
              </Flex>
            </Flex>
            <Text fontSize="sm" color={colorText} mb="4">
              O utiliza este enlace para configurar manualmente: <br />
              <a href={secret} target="_blank" rel="noopener noreferrer">
                {secret}
              </a>
            </Text>
            <Text fontSize="sm">
              Nota: Guarda el código de recuperación en un lugar seguro. Será
              necesario en caso de pérdida de acceso a la aplicación de
              autenticación.
            </Text>

            <Button variant="brand" marginTop="6px" onClick={handleVerifyClick}>
              Verificar
            </Button>
          </Box>
        </Flex>
      )}
    </>
  );
};

export default Config2FA;
