import {
 
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

import TableProducts from "./components/TableProduts";
import useProductsData from "variables/useProductsData";
import ResgisterProduct from "./components/RegisterProduct";

export default function Products() {

  const { productsData,createProduct,updateProductData } = useProductsData();

  const textColor = useColorModeValue("gray.700", "white");

  const formattedData = productsData.map((product) => ({
    id: product.id,
    code: product.code,
    name: product.name,
    category: product.category,
    stock: product.stock,
    price_points: product.price_points,
    active: product.active ? "activo" : "inactivo",
    description: product.description,
    oferta: product.oferta ? "Si" : "No",
    imagen_product: product.imagen_product,
  }));

  const columns = [
    
    { Header: "ID", accessor: "id" },
    { Header: "Codigo", accessor: "code" },
    { Header: "Producto", accessor: "name" },
    { Header: "Categoria", accessor: (row) => row.category.category_name },
    { Header: "Stock", accessor: "stock" },
    { Header: "Precio", accessor: "price_points" },
    { Header: "Estado", accessor: "active" },
    { Header: "descripcion", accessor: "description" },
    { Header: "oferta", accessor: "oferta" },
    { Header: "imagen", accessor: "imagen_product" },
    { Header: "Editar", accessor: "edit" },
  ];

  return (
    <Flex maxWidth="100%" direction="column">
      <Tabs
        colorScheme={textColor}
        size="md"
        variant="enclosed"
        pt={{ base: "180px", md: "80px", xl: "80px" }}
      >
        <TabList>
          <Tab> Todos los Productos</Tab>
          <Tab> Agregar Productos</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <TableProducts
              columnsData={columns}
              tableData={formattedData}
              updateProductData={updateProductData}
            />
          </TabPanel>
          <TabPanel>
            <ResgisterProduct createProduct={createProduct} />
          </TabPanel>
        </TabPanels>
        
      </Tabs>
    </Flex>
  );
}
