import { useAuth } from "auth-context/auth.context";
import { useEffect, useState, useCallback } from "react";
import GetBugreports from "api/bugreports";

export default function useBugsReports() {
  const { user } = useAuth();
  const [bugReportsData, setBugReportsData] = useState([]);
  const [totalBugsReports, setTotalBugsReports] = useState(0);

  const fetchData = useCallback(async () => {
    try {
      const response = await GetBugreports.getBugreports(user.token);
      setBugReportsData(response.data);
    } catch (error) {
      console.error("Error fetching bug reports:", error);
    }
  }, [user]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setTotalBugsReports(bugReportsData.length);
  }, [bugReportsData.length]);

  return {
    bugReportsData,
    totalBugsReports
  };
}
