import axios from "api";

const coupons ={
    getCoupons:(token) =>{
        return axios.get(`${base}/create-read`,{
            headers:{
                Authorization: `Bearer ${token}`
            }
        })
    },
    postCoupons:(token, data) =>{
        return axios.post(`${base}/create-read`, data,{
            headers:{
                Authorization: `Bearer ${token}`
            }
        })
    },
    updateStatusCoupon:(token,id) =>{
        return axios.post(`${base}/toggle/${id}`, null,{
            headers:{
                Authorization: `Bearer ${token}`
            }
        })
    },
    getExchangeCoupons: (token, params = {}) => {
        return axios.get(`${base1}/exchange`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        });
      },
}


let base = "coupons";
let base1="exchanges_coupons"

export default coupons