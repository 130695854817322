import React, { useState } from "react";
import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";
import { useTransactionsData } from "variables/useTransactionsData"; // Importa la función useTransactionsData
import { FaEye, FaChevronLeft, FaChevronRight } from "react-icons/fa"; // Importa los íconos de ojo y flechas

export default function AllTransactions() {
  const { transactionsData } = useTransactionsData();
  const [selectedTransaction, setSelectedTransaction] = useState(null); // Estado para almacenar la transacción seleccionada
  const [currentPage, setCurrentPage] = useState(0); // Estado para el número de página actual
  const itemsPerPage = 15; // Número de elementos por página
  //colors
  const bg = useColorModeValue("white", "color.3");
  const textColortable = useColorModeValue("color.3", "white");
  const borderColorBox = useColorModeValue("color.6", "color.6");

  // Función para manejar el cambio a la página anterior
  const previousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  // Función para manejar el cambio a la página siguiente
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Función para manejar la apertura del modal con los detalles de la transacción
  const handleOpenDetails = (transaction) => {
    const { id } = transaction;
    const rawTransaction = transactionsData.find((t) => t.id === id);
    setSelectedTransaction({ ...rawTransaction, id });
  };

  // Función para manejar el cierre del modal
  const handleCloseDetails = () => {
    setSelectedTransaction(null);
  };

  const columns = [
    { header: "Usuario", accessor: "username" },
    { header: "Email", accessor: "email" },
    { header: "Puntos", accessor: "points" },
    { header: "Descripción", accessor: "description" },
    { header: "Fecha", accessor: "created_at" },
    { header: "Hora", accessor: "hours" },
    { header: "Usuario Receptor", accessor: "recipient_user" },
  ];

  // Calcular el índice del primer elemento en la página actual
  const startIndex = currentPage * itemsPerPage;
  // Calcular el índice del último elemento en la página actual
  const endIndex = Math.min(startIndex + itemsPerPage, transactionsData.length);
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const formattedTransactionsData = transactionsData.map((item) => {
    const createdAtDate = new Date(item.created_at);
    const day = createdAtDate.getDate();
    const monthNumber = createdAtDate.getMonth();
    const year = createdAtDate.getFullYear();
    const formattedDate = `${day} de ${months[monthNumber]} de ${year}`;

    let hours = createdAtDate.getHours();
    const minutes = createdAtDate.getMinutes();
    const seconds = createdAtDate.getSeconds();
    const period = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;
    const formattedTime = `${hours}:${minutes}:${seconds} ${period}`;

    return {
      id: item.id,
      username: item.user.full_name,
      email: item.user.email,
      points: item.points,
      description: item.description,
      created_at: formattedDate,
      hours: formattedTime,
      recipient_user: item.recipient_user.full_name,
      hora: formattedTime,
    };
  });

  return (
    <>
      <Box
        borderRadius="10px"
        p="4"
        bg={bg}
        overflowY="hidden"
        overflowX="auto"
      >
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th bg={bg}></Th>
              {columns.map((column, index) => (
                <Th key={index}>{column.header}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {formattedTransactionsData
              .slice(startIndex, endIndex)
              .map((transaction) => (
                <Tr key={transaction.id}>
                  <Td>
                    <Tooltip label="Ver detalles">
                      <IconButton
                        width="10px"
                        height="10px"
                        aria-label="Ver detalles"
                        icon={<FaEye />}
                        variant="ghost"
                        onClick={() => handleOpenDetails(transaction)} // Cuando se hace clic, se abre el modal con los detalles de la transacción
                      />
                    </Tooltip>
                  </Td>
                  {columns.map((column, index) => (
                    <Td
                      key={index}
                      maxWidth="200px"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {column.accessor.includes(".")
                        ? transaction[column.accessor.split(".")[0]][
                            column.accessor.split(".")[1]
                          ]
                        : transaction[column.accessor]}
                    </Td>
                  ))}
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>

      <Modal isOpen={selectedTransaction !== null} onClose={handleCloseDetails}>
        <ModalOverlay />
        <ModalContent bg={bg}>
          <ModalHeader>Detalles de la transacción</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedTransaction && (
              <>
                <Box
                  marginBottom="4px"
                  p="4"
                  border="1px"
                  borderRadius="10px"
                  borderColor={borderColorBox}
                >
                  <Text fontSize="xl" fontWeight="bold">
                    Datos de la Transaction:
                  </Text>
                  <Text fontWeight="bold">
                    Transaction ID: {selectedTransaction.id}
                  </Text>
                  <Text>
                    <b>Creado en:</b>{" "}
                    {new Date(selectedTransaction.created_at).toLocaleString()}
                  </Text>
                  <Text>
                    <b>Actualizado en:</b>{" "}
                    {new Date(selectedTransaction.updated_at).toLocaleString()}
                  </Text>
                  <Text>
                    <b>Descripción: </b> {selectedTransaction.description}
                  </Text>
                  <Text>
                    <b>Puntos: {""}</b>
                    {selectedTransaction.points}
                  </Text>
                </Box>

                <Box
                  marginBottom="4px"
                  p="4"
                  border="1px"
                  borderRadius="10px"
                  borderColor={borderColorBox}
                >
                  <Text fontSize="xl" fontWeight="bold">
                    Datos del usuario Emisor:
                  </Text>
                  <Text>
                    <b>ID:</b> {selectedTransaction.user.id}
                  </Text>
                  <Text>
                    <b>Nombre:</b> {selectedTransaction.user.full_name}
                  </Text>
                  <Text>
                    <b>Número de documento:</b>{" "}
                    {selectedTransaction.user.num_document}
                  </Text>
                  <Text>
                    <b>Email:</b> {selectedTransaction.user.email}
                  </Text>
                  <Text>
                    <b>Teléfono:</b> {selectedTransaction.user.phone}
                  </Text>
                  <Text>
                    <b>Puntos del usuario:</b> {selectedTransaction.user.points}
                  </Text>
                </Box>

                <Box
                  marginBottom="4px"
                  p="4"
                  border="1px"
                  borderRadius="10px"
                  borderColor={borderColorBox}
                >
                  <Text fontSize="xl" fontWeight="bold">
                    {" "}
                    Datos del Usuario Receptor{" "}
                  </Text>
                  <Text>
                    <b>ID:</b> {selectedTransaction.recipient_user.id}
                  </Text>
                  <Text>
                    <b>Nombre:</b>{" "}
                    {selectedTransaction.recipient_user.full_name}
                  </Text>
                  <Text>
                    <b>Número de documento:</b>{" "}
                    {selectedTransaction.recipient_user.num_document}
                  </Text>
                  <Text>
                    <b>Email:</b> {selectedTransaction.recipient_user.email}
                  </Text>
                  <Text>
                    <b>Teléfono:</b> {selectedTransaction.recipient_user.phone}
                  </Text>
                  <Text>
                    <b>Puntos:</b> {selectedTransaction.recipient_user.points}
                  </Text>
                </Box>
              </>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* Botones de navegación */}

      <Flex justify="center" align="center" px="25px" padding="5">
        <IconButton
          icon={<FaChevronLeft />}
          disabled={currentPage === 0}
          onClick={previousPage}
        />
        <Text color={textColortable}>
          Página {currentPage + 1} de{" "}
          {Math.ceil(transactionsData.length / itemsPerPage)}
        </Text>
        <IconButton
          icon={<FaChevronRight />}
          disabled={endIndex >= transactionsData.length}
          onClick={nextPage}
        />
      </Flex>
    </>
  );
}
