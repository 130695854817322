import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import {Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import AdminLayout from "layouts/admin";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { AuthProvider } from "./auth-context/auth.context";
import { ProtectedRoute } from "./layouts/protected.route.js";
import SignIn from "views/auth/signIn";
import SetNewPassword from "views/auth/signIn/recovery";

let user = localStorage.getItem("user");
user = JSON.parse(user);
ReactDOM.render(
  <ChakraProvider theme={theme}>
    <AuthProvider userData={user}>
      <React.StrictMode>
        <BrowserRouter>
          <Switch>
          <Route path="/users/recovery-password-2/:token" component={SetNewPassword} />
            <Route path={`/auth`} component={SignIn} />
            <ProtectedRoute path={`/admin`} component={AdminLayout} />
            <Redirect from="/" to="/admin/dashboards" />
          </Switch>
        </BrowserRouter>
      </React.StrictMode>
    </AuthProvider>
  </ChakraProvider>,
  document.getElementById("root")
);
