// Chakra imports
import React, { useEffect, useState } from "react";
import {

  Flex,

  Tab,
  TabList,
  TabPanel,
  TabPanels,

  Tabs,
  useColorModeValue,
 
  
} from "@chakra-ui/react";
// Assets
// Custom components
import PointsCompany from "./components/PointsCompany";
import PointsUser from "./components/PointsUser";

export default function EduPoints() {
  
  
  // Chakra Color Mode
  const textColorTabs = useColorModeValue("color.6", "color.6");

  //const brandColor = useColorModeValue("brand.500", "white");
  //const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [currentTab, setCurrentTab] = useState(0);

  // Función para cambiar la pestaña actual
  const handleTabChange = (index) => {
    setCurrentTab(index);
  };

  // Efecto para forzar la actualización de la página al cambiar de pestaña
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentTab]);


  return (
    <Flex maxWidth="100%" direction="column">
      <Tabs
      colorScheme={textColorTabs}
        size="md"
        variant="enclosed"
        pt={{ base: "180px", md: "80px", xl: "80px" }}
        index={currentTab}
        onChange={handleTabChange} 
      >
        <TabList>
          <Tab color={textColorTabs}>Puntos de la Compañia</Tab>
          <Tab color={textColorTabs}>Puntos del Usuario</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <PointsCompany />
          </TabPanel>
          <TabPanel>
            <PointsUser />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
