import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
export default function CreateUserModal(props) {
  const { isOpen, onClose, createUser} = props;
  const colormodal = useColorModeValue("white", "color.3");
  const textColorSecondary = "gray.400";

  // Estado para almacenar los datos del formulario
  const [formData, setFormData] = useState({
    num_document: "",
    password: "",
    full_name: "",
    email: "",
    phone: "",
    user_admin: "0",
    country:"1",
    verified: "1"
  });
  // Estado para manejar los errores de los campos del formulario
  const [errorFields, setErrorFields] = useState({});
  // Toast para mostrar mensajes al usuario
  const toast = useToast();

  // Función para manejar el cambio en el campo "Rol de Usuario"
  const handleUserAdminChange = (event) => {
    const selectedValue = event.target.value;
    setFormData({ ...formData, user_admin: selectedValue });
  };

  // Función para manejar el cambio en cualquier campo del formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Estado para manejar la visibilidad de la contraseña
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  // Función para crear un nuevo usuario
  const handleCreate = async () => {
    try {
      // Validar que todos los campos estén llenos
      const emptyFields = Object.keys(formData).filter((key) => !formData[key]);
      if (emptyFields.length > 0) {
        setErrorFields(
          emptyFields.reduce((acc, key) => ({ ...acc, [key]: true }), {})
        );
        throw new Error("Por favor, ingresa todos los datos");
      }
     
      // Llamar a la función createUser con los datos del formulario
      await createUser(formData);
      // Mostrar un mensaje de éxito al usuario
      toast({
        title: "Usuario creado",
        description: "El usuario ha sido creado exitosamente.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Limpiar el formulario y cerrar el modal
      setFormData({
        num_document: "",
        password: "",
        full_name: "",
        email: "",
        phone: "",
        user_admin: "0",
      });
      onClose();
    } catch (error) {
      // Manejar cualquier error que ocurra durante la creación del usuario
      console.error("Error al crear usuario:", error);
      toast({
        title: "Error",
        description: error.message || "Hubo un error al crear el usuario.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent bg={colormodal}>
        <ModalHeader>Crear Nuevo Usuario</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Campos del formulario */}
          <FormControl mb={4} isInvalid={errorFields.num_document} isRequired>
            <FormLabel>Número de Documento</FormLabel>
            <Input
              variant="auth"
              type="text"
              name="num_document"
              value={formData.num_document}
              onChange={handleChange}
              pattern="[0-9]*" // Solo permite números
              required // Campo requerido
            />
          </FormControl>
          <FormControl mb={4} isInvalid={errorFields.full_name} isRequired>
            <FormLabel>Nombre Completo</FormLabel>
            <Input
              name="full_name"
              variant="auth"
              value={formData.full_name}
              onChange={handleChange}
              required // Campo requerido
            />
          </FormControl>
          <FormControl mb={4} isInvalid={errorFields.password} isRequired>
            <FormLabel>Contraseña</FormLabel>
            <InputGroup size="md">
              <Input
                type={show ? "text" : "password"}
                variant="auth"
                value={formData.password}
                onChange={handleChange}
                name="password"
                required // Campo requerido
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl mb={4} isInvalid={errorFields.email} isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              variant="auth"
              required // Campo requerido
            />
          </FormControl>
          <FormControl mb={4} isInvalid={errorFields.phone} isRequired>
            <FormLabel>Teléfono</FormLabel>
            <Input
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              variant="auth"
              required // Campo requerido
              type="number"
            />
          </FormControl>
          <FormControl mb={4} isRequired>
            <FormLabel>Rol de Usuario</FormLabel>
            <Select
              name="user_admin"
              value={formData.user_admin}
              onChange={handleUserAdminChange}
              variant="auth"
              required // Campo requerido
            >
              <option value="1">Administrador</option>
              <option value="0">Usuario</option>
            </Select>
          </FormControl>
        </ModalBody>

        {/* Botón para crear usuario */}
        <ModalFooter>
          <Button bg={"color.8"} onClick={handleCreate}>
            Crear Usuario
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
