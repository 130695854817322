import axios from "api";

const GetBugreports ={
    getBugreports:(token) => {
        return  axios.get(`${base}/create` , {
            headers: {
                Authorization: `Bearer ${token} `
            }
        });
    },
}

let  base ="bugReport"

export default GetBugreports