import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom"; // Importa useParams
import { useUsersData } from "variables/useUsersData";
import { RiEyeCloseLine } from "react-icons/ri"; // Importa el ícono de ojo cerrado
import { MdOutlineRemoveRedEye } from "react-icons/md"; // Importa el ícono de ojo abierto

function SetNewPassword() {
  const { getRecoveryPassword, newPasswordUpdate } = useUsersData();
  const { token } = useParams(); // Obtiene el token de la URL
  const history = useHistory();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [email, setEmail] = useState(""); // Estado para almacenar el email
  const [showNewPassword, setShowNewPassword] = useState(false); // Estado para mostrar/ocultar nueva contraseña
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Estado para mostrar/ocultar confirmación de contraseña

  // Colors
  const textColor = useColorModeValue("color.2", "white");
  const bgButton = useColorModeValue("color.6", "color.6");
  const textColorButton = useColorModeValue("color.3", "white");

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        const response = await getRecoveryPassword(token);
        setEmail(response.email); // Suponiendo que la respuesta contiene el email
      } catch (error) {
        setError("No se pudo recuperar el email. Verifique el token.");
      }
    };

    fetchEmail();
  }, [token, getRecoveryPassword]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validación de contraseñas
    if (newPassword.trim() === "" || confirmPassword.trim() === "") {
      return setError("Por favor, complete todos los campos.");
    }

    if (newPassword !== confirmPassword) {
      return setError("Las contraseñas no coinciden.");
    }

    try {
      // Construir el objeto de datos
      const data = {
        email: email, // El email recuperado
        password: newPassword, // Nueva contraseña
        token: token, // Token de la URL
      };

      // Llamar a la función para actualizar la contraseña
      await newPasswordUpdate(data);
      setSuccessMessage("Su contraseña ha sido actualizada con éxito.");
      setError(""); // Limpiar cualquier mensaje de error

      // Opcionalmente redirigir a otra página, como el inicio de sesión
      history.push("/auth/signin"); // Descomenta esto si quieres redirigir
    } catch (error) {
      setError(
        "Ocurrió un error al actualizar la contraseña. Inténtelo de nuevo."
      );
    }
  };

  const handleClickNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  return (
    <Box maxW="md" mx="auto" mt="100px" p={5} borderWidth={1} borderRadius="lg" >
      <Heading textAlign="center" mb={5}>
        Establecer Nueva Contraseña
      </Heading>
      <form onSubmit={handleSubmit}>
        <FormControl id="newPassword" mt={4} isRequired>
          <FormLabel display="flex" ms="4px" fontWeight="500" color={textColor}>
            Nueva Contraseña
          </FormLabel>
          <InputGroup size="md">
            <Input
              borderColor="gray.500"
              id="newPassword"
              type={showNewPassword ? "text" : "password"}
              variant="auth"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              autoComplete="current-password"
            />
            <InputRightElement display="flex" alignItems="center" mt="4px">
              <Icon
                color={textColor}
                _hover={{ cursor: "pointer" }}
                as={showNewPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                onClick={handleClickNewPassword}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl id="confirmPassword" mt={4} isRequired>
          <FormLabel display="flex" ms="4px" fontWeight="500" color={textColor}>
            Confirmar Contraseña
          </FormLabel>
          <InputGroup size="md">
            <Input
              borderColor="gray.500"
              id="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              variant="auth"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              autoComplete="current-password"
            />
            <InputRightElement display="flex" alignItems="center" mt="4px">
              <Icon
                color={textColor}
                _hover={{ cursor: "pointer" }}
                as={
                  showConfirmPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye
                }
                onClick={handleClickConfirmPassword}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        {error && <Box color="red">{error}</Box>}
        {successMessage && <Box color="green">{successMessage}</Box>}
        <Box textAlign="center" mt={4}>
          {" "}
          {/* Envuelve el botón en un Box centrado */}
          <Button
            bg={bgButton}
            color={textColorButton}
            type="submit"
            colorScheme="blue"
            paddingLeft={10}
            paddingRight={10}
          >
            Establecer Contraseña
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default SetNewPassword;
