import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Icon,
  Input,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
import { MdAddTask, MdAttachMoney } from "react-icons/md";
import { useUsersData } from "variables/useUsersData";
import { useAuth } from "auth-context/auth.context";

export default function PointsUser() {
  const { user } = useAuth();
  const { usersData, updatePointsUser } = useUsersData();
  const [searchUser, setSearchUser] = useState("");
  const [pointsToAddUser, setPointsToAddUser] = useState("");
  const [operation, setOperation] = useState("");
  const [userIdToUpdate, setUserIdToUpdate] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [userData, setUserData] = useState(usersData);
  const {
    num_document
  } = user.data


  //colors
  const TextColumn = useColorModeValue("color.6", "color.6");
  const textInput = useColorModeValue("color.3", "white");
  const bg = useColorModeValue("white", "color.3");
  const textColor= useColorModeValue("color.3", "white");
  const bgth = useColorModeValue("#FFFFFF", "color.3");
  const borderColorBox = useColorModeValue("color.6", "color.6");
  const colorButton = useColorModeValue("color.6", "color.6")
  const colorButtonCancel = useColorModeValue("color.5", "color.5")

  useEffect(() => {
    setUserData(usersData);
  }, [usersData]);

  const filteredUsers = userData.filter((user) => {
    return user.num_document.includes(searchUser);
  });

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchUser(value);
  };

  const handleAddPointsUser = (userId) => {
    setOperation("sumar");
    setUserIdToUpdate(userId);
    setIsDialogOpen(true);
  };

  const handleRemovePointsUser = (userId) => {
    setOperation("restar");
    setUserIdToUpdate(userId);
    setIsDialogOpen(true);
  };

  const handleConfirmOperation = async () => {
    const points = parseInt(pointsToAddUser);
    if (!isNaN(points)) {
      if (operation === "sumar") {
        await handleUpdatePointsUser(userIdToUpdate, { sumar: points });
      } else if (operation === "restar") {
        await handleUpdatePointsUser(userIdToUpdate, { restar: points });
      }
      setPointsToAddUser("");
      setIsDialogOpen(false);
    }
  };

  const handleUpdatePointsUser = async (userId, data) => {
    try {
      await updatePointsUser(userId, data);
      // Después de actualizar, actualiza userData para reflejar los cambios
      const updatedUserData = userData.map((user) => {
        if (user.id === userId) {
          return {
            ...user,
            points: data.sumar
              ? user.points + data.sumar
              : user.points - data.restar,
          };
        }
        return user;
      });
      setUserData(updatedUserData);
    } catch (error) {
      console.error("Error al actualizar los puntos del usuario:", error);
    }
  };

  return (
    <Box border="1px" borderRadius="md"bg={bg} borderColor={borderColorBox} pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex direction="column" alignItems="center">
        <FormLabel
          marginTop="5"
          fontSize="xl"
          fontWeight="bold"
          textAlign="center"
          textTransform="uppercase"
        >
          Agregar o quitar puntos al Usuario
        </FormLabel>
        <FormLabel>Ingrese el documento del Usuario</FormLabel>
        <SimpleGrid
          columns={{ base: 1, md: 1, lg: 1, "2xl": 1 }}
          gap="20px"
          mb="20px"
        >
          <Input
            color={textInput}
            value={searchUser}
            onChange={handleSearchChange}
            _placeholder={{color:textInput}}
            placeholder="Ingresa el Numero de documento "
            marginBottom="3"
            width="300px"
          />
        </SimpleGrid>

        {searchUser.length >= 6 && (
          <Box
            
         
            overflow="hidden"
            mb="30px"
          >
            <Table variant="simple" color={textInput}>
              {filteredUsers.length > 0 && (
                <Thead>
                  <Tr>
                    <Th bg={bgth} color={TextColumn} >Nombre Completo</Th>
                    <Th bg={bgth} color={TextColumn}>Numero de Documento</Th>
                    <Th bg={bgth} color={TextColumn}>Puntos</Th>
                    <Th bg={bgth} color={TextColumn}>Acciones</Th>
                  </Tr>
                </Thead>
              )}
              <Tbody>
                {filteredUsers.length > 0 &&
                  filteredUsers.map((user) => (
                    <Tr key={user.id}>
                      <Td>{user.full_name}</Td>
                      <Td>{user.num_document}</Td>
                      <Td>{user.points}</Td>
                      <Td>
                        {user.num_document !== num_document && (
                          <>
                            <Button
                              width="180px"
                              marginBottom="5px"
                              color="white"
                              variant="brand"
                              bg={colorButton}
                              leftIcon={<Icon as={MdAddTask} />}
                              onClick={() => handleAddPointsUser(user.id)}
                            >
                              Agregar Puntos
                            </Button>
                            <Button
                             variant="brand"
                              width="180px"
                              bg={colorButtonCancel}
                              leftIcon={<Icon as={MdAttachMoney} />}
                              onClick={() => handleRemovePointsUser(user.id)}
                            >
                              Quitar Puntos
                            </Button>
                          </>
                        )}
                        {user.num_document === num_document && ( // Si el número de documento coincide, mostrar un mensaje
                          <Text color="red.500">
                            Usted no tiene permitido esta opción
                          </Text>
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Box>
        )}

        <AlertDialog
          isOpen={isDialogOpen}
          leastDestructiveRef={undefined}
          onClose={() => setIsDialogOpen(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent bg={bg}>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Confirmar Operación
              </AlertDialogHeader>

              <AlertDialogBody>
                {operation === "sumar"
                  ? "¿Estás seguro de que deseas agregar puntos al usuario?"
                  : "¿Estás seguro de que deseas quitar puntos al usuario?"}
                <Input
                  color={textColor}
                  value={pointsToAddUser}
                  onChange={(e) => setPointsToAddUser(e.target.value)}
                  _placeholder={{color: textInput}}
                  placeholder="Ingrese la cantidad de puntos"
                  marginTop="3"
                />
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button bg={colorButtonCancel} onClick={() => setIsDialogOpen(false)}>Cancelar</Button>
                <Button
                
                  bg={colorButton}
                  color="white"
                  onClick={handleConfirmOperation}
                  ml={3}
                >
                  Confirmar
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Flex>
    </Box>
  );
}
