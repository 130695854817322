import { useTransactionsData } from "./useTransactionsData";
import { useEffect, useState } from "react";
import { RiArrowUpFill, RiArrowDownFill } from "react-icons/ri";

export default function useTransactionChartData(view) {
  const { transactionsDay } = useTransactionsData();
  const [chartData, setChartData] = useState([]);
  const [chartCategories, setChartCategories] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [percentageChange, setPercentageChange] = useState(0); // Porcentaje de cambio
  const [changeIcon, setChangeIcon] = useState(null); // Icono de cambio
  const [changeColor, setChangeColor] = useState(""); // Color del porcentaje de cambio

  const getLast7Days = () => {
    const result = [];
    for (let i = 6; i >= 0; i--) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      result.push(date);
    }
    return result;
  };

  useEffect(() => {
    const getCurrentMonthWeeks = () => {
      const result = [];
      const today = new Date();
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth();
      const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
      const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
      let startDate = new Date(firstDayOfMonth);
      let endDate = new Date(firstDayOfMonth);
      let weekStart = new Date(firstDayOfMonth);

      while (weekStart <= lastDayOfMonth) {
        endDate.setDate(startDate.getDate() + 6);
        if (endDate > lastDayOfMonth) {
          endDate = new Date(lastDayOfMonth);
        }
        result.push({
          start: new Date(startDate),
          end: new Date(endDate)
        });
        startDate.setDate(startDate.getDate() + 7);
        endDate.setDate(endDate.getDate() + 1);
        weekStart = new Date(startDate);
      }
      return result;
    };

    const weeks = view === 'weekly' ? getCurrentMonthWeeks() : getLast7Days();
    const categories = view === 'weekly' 
      ? weeks.map((week, index) => `Semana ${index + 1}`)
      : weeks.map(date => date.toLocaleDateString("default", { weekday: 'short' })); // ["Mon", "Tue", "Wed", ...]

    let total = 0;
    const data = weeks.map((weekOrDay, index, array) => {
      if (view === 'weekly') {
        let weekTotal = 0;
        for (let d = new Date(weekOrDay.start); d <= weekOrDay.end; d.setDate(d.getDate() + 1)) {
          const formattedDate = `${d.getDate()} ${d.toLocaleString("default", { month: "short" })} ${d.getFullYear()}`;
          weekTotal += transactionsDay[formattedDate] || 0;
        }
        total += weekTotal;
        return weekTotal;
      } else {
        const date = weekOrDay;
        const formattedDate = `${date.getDate()} ${date.toLocaleString("default", { month: "short" })} ${date.getFullYear()}`;
        const dayTotal = transactionsDay[formattedDate] || 0;
        total += dayTotal;

        // Calcular el porcentaje de cambio si estamos en la última semana
        if (index === array.length - 1 && index > 0) {
          const previousWeekTotal = array[index - 1];
          const change = (dayTotal - previousWeekTotal) / previousWeekTotal * 100;
          setPercentageChange(change.toFixed(2)); // Redondear a 2 decimales

          // Establecer el icono, color y texto basado en el cambio
          if (change > 0) {
            setChangeIcon(<RiArrowUpFill color="green" />);
            setChangeColor("green");
          } else if (change < 0) {
            setChangeIcon(<RiArrowDownFill color="red" />);
            setChangeColor("red");
          } else {
            setChangeColor("");
          }
        }
        
        return dayTotal;
      }
    });

    setChartCategories(categories);
    setChartData(data);
    setTotalTransactions(total);
  }, [transactionsDay, view]);

  const barChartDataDailyTraffic = [
    {
      name: view === 'weekly' ? "Transacciones Semanales" : "Transacciones Diarias",
      data: chartData,
    },
  ];

  const barChartOptionsDailyTraffic = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: chartCategories,
      show: false,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#CBD5E0",
          fontSize: "14px",
        },
      },
    },
    grid: {
      show: false,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          [
            {
              offset: 0,
              color: "#09155F",
              opacity: 1,
            },
            {
              offset: 100,
              color: "rgba(0, 87, 255, 1)",
              opacity: 0.28,
            },
          ],
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "40px",
      },
    },
  };

  return {
    barChartDataDailyTraffic,
    barChartOptionsDailyTraffic,
    totalTransactions,
    percentageChange,
    changeIcon,
    changeColor
  };
}
