import React from "react";
import { Flex, Box, Image, useColorModeValue } from "@chakra-ui/react";
import logo1 from '../../../assets/img/auth/isologo-blanco.png';
import logo2 from '../../../assets/img/auth/isologo-blanco.png';

export function SidebarBrand() {
  // Determina el color de fondo del contenedor del logo según el modo de color actual de Chakra
const logo = useColorModeValue(logo2, logo1)
  return (
    <Flex align='center' direction='column'>
      <Box  p="30px" >
        <Image src={logo} alt="Logo" style={{ width: "50px", height: "50px" }} />
      </Box>
    </Flex>
  );
}

export default SidebarBrand;
