// Chakra Imports
import {
 
    Flex,
    useColorModeValue,
   
  } from "@chakra-ui/react";
  // Custom Components
  import { SearchBar } from "components/navbar/searchBar/SearchBar";
  import React from "react";
  // Assets
 
  export default function NavbarLinksUser(props) {
  
    const { secondary } = props;
    const { searchTerm, onChange, onSearch } = props; 

    // Chakra Color Mode
   

   
    const shadow = useColorModeValue(
      "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
      "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );
    //const borderButton = useColorModeValue("secondaryGray.500", "whiteAlpha.200");
    return (
      <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems='center'
      flexDirection='row'
    
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p='10px'
      borderRadius='30px'
      boxShadow={shadow}>
        <SearchBar 
        searchTerm={searchTerm} 
        onChange={onChange} 
        onSearch={onSearch}
      />
      </Flex>
    );
  }
  