import axios from "api";

const points ={
   
      getPointsCompany: (token) =>{
        return axios.get (`${company}/get-points`,{
          headers:{
            Authorization: `Bearer ${token}`
          }
        })
      },
      updatePointsCompany: (token, data) =>{
        return axios.post(`${company}/addless`,data,{
          headers:{
            Authorization: `Bearer ${token}`
          }
        })
      }
}

let company="points_company"

export default points