import React from "react";
import TablaCompras from "./components/TableCompras";
import useExchangeProducts from "variables/useExchangeProducts";

export default function Compras(props) {
  const { usersData, filteredTransactionsData, handleFilterChange } =
    useExchangeProducts();

  const columns = [
    { Header: "", accessor: "id" },
    { Header: "Codigo Producto", accessor: "code" },
    { Header: "Producto", accessor: "productos" },
    { Header: "Estado", accessor: "status" },
    { Header: "Usuario", accessor: "user" },
    { Header: "Fecha de Compra", accessor: "created_at" },
    { Header: "Hora", accessor: "hora" },
  ];

  const formattedComprasData = filteredTransactionsData.map((item) => {
    const months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    const user = usersData.find((user) => user.id === item.user);
    const userName = user ? user.full_name : "";
    const createdAtDate = new Date(item.created_at);
    const day = createdAtDate.getDate();
    const monthNumber = createdAtDate.getMonth();
    const monthName = months[monthNumber];
    const year = createdAtDate.getFullYear();
    const formattedDate = `${day} de ${monthName} de ${year}`;

    let hours = createdAtDate.getHours();
    const minutes = createdAtDate.getMinutes();
    const seconds = createdAtDate.getSeconds();
    const period = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;
    const formattedTime = `${hours}:${minutes}:${seconds} ${period}`;

    return {
      id: item.id,
      code: item.productos.code,
      productos: item.productos.name,
      status: item.status,
      user: userName,
      created_at: formattedDate,
      hora: formattedTime,
    };
  });

  return (
    <TablaCompras
      tableData={formattedComprasData}
      columnsData={columns}
      onFilterChange={handleFilterChange}
    />
  );
}
