import { useEffect, useRef, useState } from "react";
import exchangeProducts from "api/exchangeProducts";
import GetUsers from "api/users";
import { useAuth } from "auth-context/auth.context";

export default function useExchangeProducts() {
  const isMounted = useRef(true);
  const { user } = useAuth();
  const [transactionsData, setTransactionsData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [filteredTransactionsData, setFilteredTransactionsData] = useState([]);
  const [totalExhangeData, setTotalExchangeData] = useState(0);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchComprasData = async () => {
      try {
        const transactionsResponse = await exchangeProducts.getTransaction(
          user.token
        );
        const usersResponse = await GetUsers.getUsers(user.token);
        // Actualizar el estado solo si el componente está montado
        if (isMounted.current) {
          setTransactionsData(transactionsResponse.data);
          setUsersData(usersResponse.data);
          setFilteredTransactionsData(transactionsResponse.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Llamar a la función fetchComprasData al montar el componente
    fetchComprasData();

    // Función de limpieza para cancelar la suscripción cuando el componente se desmonta
    return () => {
      isMounted.current = false; // Establecer la bandera en false cuando el componente se desmonta
    };
  }, [user.token]); // Ejecutar este efecto solo una vez al montar el componente

  useEffect(() => {
    setTotalExchangeData(transactionsData.length);
  }, [transactionsData]);

  const handleFilterChange = (value) => {
    if (!value.trim()) {
      setFilteredTransactionsData(transactionsData);
    } else {
      const filteredData = transactionsData.filter((transaction) => {
        const user = usersData.find((user) => user.id === transaction.user);
        return (
          user &&
          ((user.num_document && user.num_document.includes(value.trim())) ||
            (user.full_name &&
              user.full_name
                .toLowerCase()
                .includes(value.trim().toLowerCase())))
        );
      });
      setFilteredTransactionsData(filteredData);
    }
  };

  const updateState = async (id, newStatusId) => {
    try {
      await exchangeProducts.updateState(user.token, id, newStatusId);
      const transactionsResponse = await exchangeProducts.getTransaction(
        user.token
      );
      setTransactionsData(transactionsResponse.data);
    } catch (error) {
      console.error("Error updating estado:", error);
    }
  };

  return {
    transactionsData,
    usersData,
    filteredTransactionsData,
    totalExhangeData,
    handleFilterChange,
    updateState,
  };
}
