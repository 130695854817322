import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  Button,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Box,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import { useAuth } from "auth-context/auth.context";
import GetCategory from "api/category";
import CategoryModal from "views/admin/Categorias/NewCategorie";

export default function RegisterProduct({ createProduct }) {
  const [categories, setCategories] = useState([]);
  const { user } = useAuth();
  const [isModalOpenCategory, setIsModalOpenCategory] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  //const colorButton =useColorModeValue( "navy.700","red")
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const colorButton=useColorModeValue("color.6", "color.6")


  const [productData, setProductData] = useState({
    name: "",
    stock: "",
    price_points: "",
    category: "",
    code: "",
    description: "",
    active: "",
    oferta: "",
    imagen_product: null,
  });
  const [descriptionLetterCount, setDescriptionLetterCount] = useState(0);
  const maxDescriptionLetters = 200;

  useEffect(() => {
    async function fetchCategories() {
      try {
        const response = await GetCategory.getCategory(user.token);
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    }
    if (user && user.token) {
      fetchCategories();
    }
  }, [user, isModalOpenCategory]); // Actualizar categorías cuando se cierra el modal

  const sendProductData = async () => {
    try {
      createProduct(productData);
      setIsConfirmModalOpen(false);
      clearFields();
    } catch (error) {
      console.error("Error al enviar el producto:", error);
    }
  };

  const handleOpenModalCategory = () => {
    setIsModalOpenCategory(true); // Abre el modal
  };

  const handleCloseModalCategory = () => {
    setIsModalOpenCategory(false);
  };

  const handleOpenConfirmModal = () => {
    setIsConfirmModalOpen(true);
  };

  const handleCloseConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const clearFields = () => {
    setProductData({
      name: "",
      stock: "",
      price_points: "",
      category: "",
      code: "",
      description: "",
      active: "",
      oferta: "",
      imagen_product: null,
    });
  };

  const handleDescriptionChange = (event) => {
    const text = event.target.value;
    const letterCount = text.length;
    setDescriptionLetterCount(letterCount);
    if (letterCount > maxDescriptionLetters) {
      setProductData({ ...productData, description: text.substring(0, 200) });
    } else {
      setProductData({ ...productData, description: text });
    }
  };

  const validateFields = () => {
    return (
      productData.name !== "" &&
      productData.stock !== "" &&
      productData.price_points !== "" &&
      productData.category !== "" &&
      productData.code !== "" &&
      productData.active !== "" &&
      productData.description !== "" &&
      productData.oferta !== ""
    );
  };

  return (
    <Flex justifyContent="center" width="100%" maxW="100%">
      <Box
        borderRadius="30px"
        width="600px"
        border="1px"
        borderColor={colorButton}
        overflowY="hidden"
        overflowX="auto"
      >
        <VStack padding="20px" spacing={4} align="stretch">
          <FormControl isRequired>
            <FormLabel>Código:</FormLabel>
            <Input
              color={textColor}
              name="code"
              type="text"
              value={productData.code}
              onChange={(e) =>
                setProductData({ ...productData, code: e.target.value })
              }
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Nombre:</FormLabel>
            <Input
              color={textColor}
              name="name"
              type="text"
              value={productData.name}
              onChange={(e) =>
                setProductData({ ...productData, name: e.target.value })
              }
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Categoría:</FormLabel>
            <Select
              name="category"
              value={productData.category}
              onChange={(e) =>
                setProductData({ ...productData, category: e.target.value })
              }
            >
              <option value="">Selecciona una categoría</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.category_name}
                </option>
              ))}
              <option value="No existe">No existe</option>
            </Select>
          </FormControl>
          {productData.category === "No existe" && (
            <Button variant="brand" bg={"color.6"} onClick={handleOpenModalCategory}>
              Agregar Categoría
            </Button>
          )}
          <FormControl isRequired>
            <FormLabel>Stock:</FormLabel>
            <Input
              color={textColor}
              name="stock"
              type="number"
              value={productData.stock}
              onChange={(e) =>
                setProductData({ ...productData, stock: e.target.value })
              }
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Precio:</FormLabel>
            <Input
              color={textColor}
              name="price_points"
              type="number"
              value={productData.price_points}
              onChange={(e) =>
                setProductData({ ...productData, price_points: e.target.value })
              }
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>
              Descripción ({descriptionLetterCount}/{maxDescriptionLetters}):
            </FormLabel>
            <Textarea
              color={textColor}
              name="description"
              value={productData.description}
              onChange={handleDescriptionChange}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Estado:</FormLabel>
            <Select
              name="Estado"
              value={productData.active}
              onChange={(e) =>
                setProductData({ ...productData, active: e.target.value })
              }
            >
              <option value="">Selecciona una opción</option>
              <option value="1">Activo</option>
              <option value="0">Inactivo</option>
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Oferta:</FormLabel>
            <Select
              name="oferta"
              value={productData.oferta}
              onChange={(e) =>
                setProductData({ ...productData, oferta: e.target.value })
              }
            >
              <option value="">Selecciona una opción</option>
              <option value="1">Sí</option>
              <option value="0">No</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Imagen:</FormLabel>
            <Input
              color={textColor}
              name="imagen_product"
              type="file"
              onChange={(e) =>
                setProductData({
                  ...productData,
                  imagen_product: e.target.files[0],
                })
              }
            />
          </FormControl>
          <Flex justify="center">
            <Button
              width="150px"
              variant="brand"
              bg={colorButton}
              onClick={handleOpenConfirmModal}
              disabled={!validateFields()}
            >
              GUARDAR
            </Button>
          </Flex>
          {isModalOpenCategory && (
            <CategoryModal
              isOpen={isModalOpenCategory}
              onClose={handleCloseModalCategory}
            />
          )}
          <Modal isOpen={isConfirmModalOpen} onClose={handleCloseConfirmModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Confirmar</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>¿Estás seguro de que deseas guardar este producto?</Text>
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={sendProductData}>
                  Sí
                </Button>
                <Button variant="brand" onClick={handleCloseConfirmModal}>
                  Cancelar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </VStack>
      </Box>
    </Flex>
  );
}
