import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, FormControl, FormLabel, Input, InputGroup, InputRightElement, IconButton, useColorModeValue } from '@chakra-ui/react';
import { useUsersData } from 'variables/useUsersData';
import { HiEye, HiEyeOff } from 'react-icons/hi';

const ChangePasswordModal = ({ isOpen, onClose }) => {
    const { changePassword } = useUsersData();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword1, setNewPassword1] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const [showPassword, setShowPassword] = useState(false);
    //Colors
    const bg = useColorModeValue("white", "color.3");
    const colorButton = useColorModeValue("color.6", "color.6")
    const textInput = useColorModeValue("color.3", "white");
    const colorButtonCancel = useColorModeValue("color.5", "color.5")


    const handleSubmit = () => {
        // Verificar si las nuevas contraseñas coinciden
        if (newPassword1 !== newPassword2) {
            console.error("Las nuevas contraseñas no coinciden");
            return;
        }
        // Llamar a la función changePassword con los datos proporcionados
        changePassword({ old_password: oldPassword, new_password1: newPassword1, new_password2: newPassword2 });
        // Cerrar el modal después de enviar el formulario
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bg={bg}>
                <ModalHeader>Cambiar Contraseña</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl mb={4}>
                        <FormLabel>Contraseña Antigua</FormLabel>
                        <InputGroup>
                            <Input
                                color={textInput}
                                type={showPassword ? "text" : "password"}
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                            />
                            <InputRightElement>
                                <IconButton
                                color={textInput}
                                    variant="ghost"
                                    aria-label={showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
                                    icon={showPassword ? <HiEyeOff /> : <HiEye />}
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                    <FormControl mb={4}>
                        <FormLabel>Nueva Contraseña</FormLabel>
                        <InputGroup>
                            <Input
                                color={textColor}
                                type={showPassword ? "text" : "password"}
                                value={newPassword1}
                                onChange={(e) => setNewPassword1(e.target.value)}
                            />
                            <InputRightElement>
                                <IconButton
                                    variant="ghost"
                                    aria-label={showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
                                    icon={showPassword ? <HiEyeOff /> : <HiEye />}
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                    <FormControl mb={4}>
                        <FormLabel>Confirmar Nueva Contraseña</FormLabel>
                        <InputGroup>
                            <Input
                                color={textColor}
                                type={showPassword ? "text" : "password"}
                                value={newPassword2}
                                onChange={(e) => setNewPassword2(e.target.value)}
                            />
                            <InputRightElement>
                                <IconButton
                                    variant="ghost"
                                    aria-label={showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
                                    icon={showPassword ? <HiEyeOff /> : <HiEye />}
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                </ModalBody>
                <ModalFooter justifyContent="center">
                    <Button bg={colorButton} color="white" onClick={handleSubmit}>Cambiar Contraseña</Button>
                    <Button marginLeft="8px" bg={colorButtonCancel}  onClick={onClose}>Cancelar</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ChangePasswordModal;
