import React, { useEffect, useMemo, useState } from "react";
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Select,
  IconButton,
  useColorModeValue,
  Icon,
  Box,
} from "@chakra-ui/react";
import {
  MdChevronLeft,
  MdChevronRight,
  MdArrowDownward,
  MdArrowUpward,
  MdSort,
} from "react-icons/md";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Getusers from "api/users";
import { useAuth } from "auth-context/auth.context";

function AllUsers(props) {
  const { columnsData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const [data, setData] = useState(props.tableData);
  const [sortBy, setSortBy] = useState([]);
  const { user } = useAuth();
  /*const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const inputBg = useColorModeValue("white", "secondaryGray.800");
  const inputColor = useColorModeValue("gray.900", "white");
  let menuBg = useColorModeValue("white", "navy.800");*/
  const bg = useColorModeValue("#FFFFFF", "color.3");

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5, sortBy: [{ id: "id", desc: true }] },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageSize, setPageSize } = tableInstance;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state: { pageIndex },
  } = tableInstance;

  const handleStateChange = async (rowIndex, newValue) => {
    const confirmation = window.confirm(
      "¿Estás seguro de cambiar el estado del usuario?"
    );
    if (!confirmation) return;
    try {
      const newData = [...data];
      newData[rowIndex].user_active = newValue;
      const userId = newData[rowIndex].id;

      // Actualizar el estado local primero para reflejar el cambio instantáneamente
      setData(newData);

      // Llamar a la función para actualizar el estado en el servidor
      await Getusers.updateState(user.token, userId);
    } catch (error) {
      // Manejar errores si es necesario
      console.error("Error al cambiar el estado del usuario:", error);
    }
  };
  useEffect(() => {
    setData(props.tableData);
  }, [props.tableData]);

  // Función para manejar el cambio de ordenamiento
  const handleSort = (columnId) => {
    // Comprobar si el usuario ha hecho clic en la misma columna
    const isSameColumn = sortBy[0] && sortBy[0].id === columnId;
    // Obtener la dirección del ordenamiento actual
    const sortDirection = isSameColumn
      ? sortBy[0].desc
        ? "asc"
        : "desc"
      : "desc";
    // Actualizar el estado 'sortBy' con la columna y la dirección de ordenamiento
    setSortBy([{ id: columnId, desc: sortDirection }]);
  };

  return (
    <Box p="4">
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Flex>
          <Select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 15, 20].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize} usuarios por página
              </option>
            ))}
          </Select>
        </Flex>
      </Flex>
      <Box
        bg={bg}
        p={"4"}
        borderRadius="10"
        overflowY="hidden"
        overflowX="auto"
      >
        <Table {...getTableProps()} variant="striped">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      cursor="pointer"
                      onClick={() => handleSort(column.id)}
                    >
                      {column.render("Header")}
                      {/* Mostrar un icono para indicar la dirección del ordenamiento */}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Icon as={MdArrowDownward} color="color.7" />
                        ) : (
                          <Icon as={MdArrowUpward} color="color.7" />
                        )
                      ) : (
                        <Icon as={MdSort} color="color.7" />
                      )}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);

              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let content = cell.render("Cell");
                    // Si la columna es "Estado", muestra un select
                    if (cell.column.Header === "Estado") {
                      const rowIndex = row.index;
                      const isDisabled =
                        user.data.num_document === row.original.num_document;
                      content = (
                        <Select
                          width="110px"
                          value={cell.value}
                          onChange={(e) =>
                            handleStateChange(rowIndex, e.target.value)
                          }
                          disabled={isDisabled}
                        >
                          <option value="activo">activo</option>
                          <option value="inactivo">inactivo</option>
                        </Select>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                        {content}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
      <Flex justify="center" align="center" px="25px">
        <IconButton
          icon={<MdChevronLeft />}
          disabled={!canPreviousPage}
          onClick={previousPage}
        />
        <Text>
          Página {pageIndex + 1} de {pageOptions.length}
        </Text>
        <IconButton
          icon={<MdChevronRight />}
          disabled={!canNextPage}
          onClick={nextPage}
        />
      </Flex>
    </Box>
  );
}

export default AllUsers;
