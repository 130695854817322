import axios from "api";

const GetProducts ={
    getProducts: (token) => {
        return axios.get(`${base}/products_admin`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      },
      postProducts: (token, data) => {

        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
   
        return axios.post(`${base}/products_admin`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data' 
          }
          
        });
      },
      putProducts:(token, id, data)=>{

        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        return axios.put(`${base}/products_admin/${id}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data' 
          }
        })
      }
}

let base = "products";

export default GetProducts;