import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdHome,
  MdLogout,
  MdLocalGroceryStore,
  MdLocalOffer,
  MdBugReport,
  MdPerson,
  MdVerified,
} from "react-icons/md";
import { FaBox, FaCoins, FaExchangeAlt } from "react-icons/fa";
import { ImUsers } from "react-icons/im";


// Admin Imports
import MainDashboard from "views/admin/default";
import Users from "views/admin/users";
//import Profile from "views/admin/profile";

import Products from "views/admin/products";
import Compras from "views/admin/compras";
import EduPoints from "views/admin/eduPoints";
import TransactionCard from "views/admin/Transactions";
import Profile from "views/admin/profile";

// Auth Imports
// import SignInCentered from "views/auth/signIn";
import Cupons from "views/admin/cupons";
import BugReport from "views/admin/bugReports";
// Auth Imports
// import SignInCentered from "views/auth/signIn";
import SignIn from "views/auth/signIn/index.jsx";
import KYC from "views/admin/kyc";
import SetNewPassword from "views/auth/signIn/recovery";




const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
   
  },
  {
    name: "Perfil",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
    secondary: true,
  },
  {
    name: "Usuarios",
    layout: "/admin",
    path: "/usuarios",
    icon: <Icon as={ImUsers} width="20px" height="20px" color="inherit" />,
    component: Users,
    
  },
  {
    name: "Productos",
    layout: "/admin",
    icon: <Icon as={FaBox} width="20px" height="20px" color="inherit" />,
    path: "/data-tables",
    component: Products,
  },
  {
    name: "Compras",
    layout: "/admin",
    path: "/compras",
    icon: (
      <Icon
        as={MdLocalGroceryStore}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    
    component: Compras,
  },
  {
    name: "Transacciones",
    layout: "/admin",
    path: "/transacciones",
    icon: (
      <Icon as={FaExchangeAlt} width="20px" height="20px" color="inherit" />
    ),
    component: TransactionCard
  },
  {
    name: "EduPoints",
    layout: "/admin",
    path: "/points",
    icon: <Icon as={FaCoins} width="20px" height="20px" color="inherit" />,
    component: EduPoints,
  },
  {
    name: "Cupones",
    layout: "/admin",
    path: "/cupones",
    icon: <Icon as={MdLocalOffer} width="20px" height="20px" color="inherit" />,
    component: Cupons,
  },
  {
    name: "Reportes de Bugs",
    layout: "/admin",
    path: "/bug-reports",
    icon: <Icon as={MdBugReport} width="20px" height="20px" color="inherit" />,
    component: BugReport,
  },
  {
    name: "Verificación",
    layout: "/admin",
    path: "/kyc",
    icon: <Icon as={MdVerified} width="20px" height="20px" color="inherit" />,
    component: KYC,
  },
  {
    layout: "/auth",
    path: "/recovery-password-2/:token",
    component: SetNewPassword,
  },

];

export const Logout = [
  {
    name: "Log Out",
    layout: "/auth",
    path: "/sign-out",
    icon: <Icon as={MdLogout} width="16px" height="16px" color="inherit" />,
    component: SignIn,
  },
];
export default routes;
